import React from 'react';
import s from './AudioCbuCprpChart.module.css';
import { Line } from 'react-chartjs-2';
import { round, removeItemAll } from '../../../../helpers/chart_helpers';

export const AudioCbuCprpChart = React.memo(props => {
  const { AudioCbuCprpChartData, freqAll } = props;

  const R = AudioCbuCprpChartData.datasets && removeItemAll([...AudioCbuCprpChartData.datasets[0].data], 'null');
  const RIncr = AudioCbuCprpChartData.datasets && removeItemAll([...AudioCbuCprpChartData.datasets[1].data], 'null');
  const CprpIncr = AudioCbuCprpChartData.datasets && removeItemAll([...AudioCbuCprpChartData.datasets[2].data], 'null');
  const CprpIncr_RIncr = RIncr && RIncr.concat(CprpIncr);

  const maxValueR = Math.ceil(Math.max.apply(Math, R));
  const minValueCprpIncr_RIncr = Math.floor(Math.min.apply(Math, CprpIncr_RIncr));

  const options = {
    interaction: {
      mode: 'index',
      intersect: false,
    },

    elements: {
      point: {
        radius: 0,
      },
    },
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: true,
        position: 'bottom',
        align: 'centre',
        labels: {
          boxHeight: 0.5,
        },
      },
      tooltip: {
        enabled: true,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        titleColor: 'black',
        bodyColor: 'black',
        boxHeight: 1,
        callbacks: {
          label: function (TooltipItem) {
            return TooltipItem.dataset.label + ': ' + round(TooltipItem.parsed.y, 1) + ` %`;
          },
          title: function (TooltipItem) {
            return TooltipItem[0].label + ' млн';
          },
        },
      },
    },
    scales: {
      RIncr: {
        min: minValueCprpIncr_RIncr,
        max: 20,
        position: 'left',
        title: {
          display: true,
          align: 'start',
          text: `Incremental Reach ${freqAll}`,
          color: 'grey',
          font: {
            size: 13,
          },
          padding: { top: 5, left: 0, right: 0, bottom: 0 },
        },
        ticks: {
          callback: function (val, index) {
            return this.getLabelForValue(val) + '%';
          },
        },
      },
      R: {
        min: 0,
        max: maxValueR,
        position: 'right',
        title: {
          display: true,
          align: 'start',
          text: `Reach ${freqAll}`,
          color: 'grey',
          font: {
            size: 13,
          },
          padding: { top: 5, left: 0, right: 0, bottom: 0 },
        },
        ticks: {
          callback: function (val, index) {
            return this.getLabelForValue(val) + '%';
          },
        },
      },
      x: {
        title: {
          display: true,
          align: 'start',
          text: 'Бюджет (млн. руб)',
          color: 'grey',
          font: {
            size: 13,
          },
          padding: 0,
        },
      },
    },
  };

  return (
    <div className={s.wrapper}>
      <Line width={300} height={220} data={AudioCbuCprpChartData} options={options} />
    </div>
  );
});
