import React from 'react';
import s from './AudioDistributionChart.module.css';
import { Bar, Chart } from 'react-chartjs-2';
import { round } from '../../../../helpers/chart_helpers';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(ChartDataLabels);

export const AudioDistributionChart = React.memo(props => {
  const { olvDistribution_data } = props

  const options = {
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        titleColor: 'black',
        bodyColor: 'black',
        callbacks: {
          label: function (TooltipItem) {
            return 'R ' + TooltipItem.label + ': ' + round(TooltipItem.parsed.y, 1) + ` %`;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          align: 'start',
          text: 'Frequency',
          color: 'grey',
          font: {
            size: 13,
          },
          padding: 0,
        },
      },

      y: {
        title: {
          display: true,
          align: 'start',
          text: 'Reach %',
          color: 'grey',
          font: {
            size: 13,
          },
          padding: { top: 5, left: 0, right: 0, bottom: 0 },
        },
        ticks: {
          callback: function (val, index) {
            return this.getLabelForValue(val) + ' %';
          },
        },
      },
    },
  };

  return (
    <div className={s.wrapper}>
      <Bar data={olvDistribution_data} options={options} width={300} height={230} />
    </div>
  );
});
