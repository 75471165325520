import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './Header.module.css';
import { useHistory } from 'react-router-dom';
import redStarBig from '../../../assets/images/RedStarBig.png';
import redLineBig from '../../../assets/images/RedLineBig.png';
import { setTabbedCalculatorIsActive, setTabbedInstructionIsActive } from '../../../bll/app_reducer';

export const Header = React.memo(() => {
  const dispatch = useDispatch();
  const tabbedCalculatorIsActive = useSelector(state => state.app.tabbedCalculatorIsActive);
  const tabbedInstructionIsActive = useSelector(state => state.app.tabbedInstructionIsActive);

  const onClickTabbedCalculator = () => {
    dispatch(setTabbedCalculatorIsActive(true));
    dispatch(setTabbedInstructionIsActive(false));
    history.push('/');
  };
  const onClickTabbedInstruction = () => {
    dispatch(setTabbedInstructionIsActive(true));
    dispatch(setTabbedCalculatorIsActive(false));
    history.push('/instruction');
  };
  const history = useHistory();
  return (
    <div className={s.bar}>
      <div className={s.barContent}>
        <div className={s.delimiterWrapper}>
          <img src={redStarBig} alt='redStarSmall' />
          <div className={s.logo}>
            <span> Audio</span> Tron
          </div>
          <img src={redLineBig} alt='redLineBig' style={{ width: '65%', height: '6px' }} />
        </div>

        <div className={s.tabbedBlock}>
          <button className={tabbedCalculatorIsActive ? `${s.active} ${s.headerButton}` : s.headerButton} onClick={onClickTabbedCalculator}>
            AudioTron
          </button>
          <button className={tabbedInstructionIsActive ? `${s.active} ${s.headerButton}` : s.headerButton} onClick={onClickTabbedInstruction}>
            Инструкция
          </button>
        </div>
      </div>
    </div>
  );
});
