import { setIsInputDataChanged, setIsInputDataPart1Changed } from '../bll/inputParameters_reducer';
import { setIsChartsUpload, setAllChartsData, setDataFromXL, setDonutData, setContributionOfEachMedia_data, setDistribution_data, setAudioCbuCprpChartData, setOptInvestmentChart_data, setOptComparisonChart_data, setRadioDistributionChart_data, setRadioIncreaseAggregateCoverageChart_data, setRadioOptimalDistributionChart_data, setRadioOptimalSplitChart_data, setChartsData_TV_OLV_RFdistr2, setChartsData_TV_OLV_20_view } from '../bll/charts_reducer';

export const handleInputDataPart1Changed = dispatch => {
  dispatch(setIsInputDataPart1Changed(true));
  dispatch(setIsInputDataChanged(true));
  dispatch(setIsChartsUpload(false));
  dispatch(setAllChartsData({}));
  dispatch(setDataFromXL([]));

  dispatch(setDonutData({}));
  dispatch(setContributionOfEachMedia_data({}));
  dispatch(setDistribution_data({}));
  dispatch(setAudioCbuCprpChartData({}));
  dispatch(setOptInvestmentChart_data({}));
  dispatch(setOptComparisonChart_data({}));
  dispatch(setRadioDistributionChart_data({}));
  dispatch(setRadioIncreaseAggregateCoverageChart_data({}));
  dispatch(setRadioOptimalDistributionChart_data({}));
  dispatch(setRadioOptimalSplitChart_data({}));
  dispatch(setChartsData_TV_OLV_RFdistr2({}));
  dispatch(setChartsData_TV_OLV_20_view({}));
};

export const handleInputDataPart2Changed = dispatch => {
  dispatch(setIsInputDataChanged(true));
  dispatch(setIsChartsUpload(false));
  dispatch(setAllChartsData({}));
  dispatch(setDataFromXL([]));

  dispatch(setDonutData({}));
  dispatch(setContributionOfEachMedia_data({}));
  dispatch(setDistribution_data({}));
  dispatch(setAudioCbuCprpChartData({}));
  dispatch(setOptInvestmentChart_data({}));
  dispatch(setOptComparisonChart_data({}));
  dispatch(setRadioDistributionChart_data({}));
  dispatch(setRadioIncreaseAggregateCoverageChart_data({}));
  dispatch(setRadioOptimalDistributionChart_data({}));
  dispatch(setRadioOptimalSplitChart_data({}));
  dispatch(setChartsData_TV_OLV_RFdistr2({}));
  dispatch(setChartsData_TV_OLV_20_view({}));
};
