import React, { useEffect } from 'react';
import s from './RadioOptimalSplitChart.module.css';
import { Bar, Chart } from 'react-chartjs-2';
import { round, getMaxValueOfArrayNotRound, findMaxMinOhvat } from '../../../../helpers/chart_helpers';
import { setMillion } from '../../../../helpers/common_helpers';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(ChartDataLabels);

export const RadioOptimalSplitChart = React.memo(props => {
  const { cbu, cpp, RadioOptimalSplitChart_data } = props 
  const reachArr = RadioOptimalSplitChart_data.datasets && RadioOptimalSplitChart_data.datasets[0].data;
  const allBudget = setMillion(cbu[cbu.length-1][0]*cpp)
 
  const maxOhvat = getMaxValueOfArrayNotRound(reachArr);
  const options = {
    interaction: {
      mode: 'index',
    },
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 2,
      },
    },
    plugins: {
      datalabels: {
        display: 'true',
        backgroundColor: 'black',
        color: 'white',
        padding: 1,
        formatter: function (value) {
          if (value === maxOhvat) {
            const changedValue = round(value, 1);
            return changedValue + ` %`;
          } else {
            return null;
          }
        },
        labels: {
          title: {
            font: {
              weight: 'bold',
              size: '11px',
            },
          },
        },
        anchor: 'bottom',
        align: 'top',
      },
      legend: {
        display: false,
        position: 'bottom',
        align: 'centre',
        usePointStyle: true,
      },
      tooltip: {
        enabled: true,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        titleColor: 'black',
        bodyColor: 'black',
        title: 'null',

        callbacks: {
          afterBody: function (TooltipItem) {  
            return `Общий бюджет: ${allBudget} млн`
          },
        },







      },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          align: 'start',
          text: 'Бюджет Radio (млн. руб)',
          color: 'grey',
          font: {
            size: 13,
          },
          padding: 0,
        },
      },

      y: {
        stacked: true,
        title: {
          display: true,
          align: 'start',
          text: 'Бюджет на медиа',
          color: 'grey',
          font: {
            size: 13,
          },
          padding: { top: 5, left: 0, right: 0, bottom: 0 },
        },
        ticks: {
          callback: function (val, index) {
            return round(setMillion(val), 1);
          },
        },
      },
      ohvat: {
        min: findMaxMinOhvat(reachArr)[0],
        max: findMaxMinOhvat(reachArr)[1],
        position: 'right',
        stacked: true,
        grid: { lineWidth: 0 },
        title: {
          display: true,
          align: 'end',
          text: `Совокупный охват Radio&Audio`,
          color: 'grey',
          font: {
            size: 13,
          },
          padding: { top: 5, left: 0, right: 0, bottom: 0 },
        },
        ticks: {
          callback: function (val, index) {
            return this.getLabelForValue(val) + '%';
          },
        },
      },
    },
  };
  useEffect(() => {
    RadioOptimalSplitChart_data.datasets && RadioOptimalSplitChart_data.datasets.map(item => (document.getElementById(item.label).style.backgroundColor = item.backgroundColor));
  }, [RadioOptimalSplitChart_data]);

  return (
    <div>
      <div className={s.wrapper}>
        <Bar data={RadioOptimalSplitChart_data} options={options} width={1000} height={220} />
      </div>
      <div className={s.lenendsBox}>
        {RadioOptimalSplitChart_data.datasets &&
          RadioOptimalSplitChart_data.datasets.map(item => (
            <div className={s.legendWrapper} key={item.label}>
              <div id={item.label} className={item.label === 'Охват на заданной частоте' ? s.ohvatLegend : s.legend}></div> <span>{item.label}</span>
            </div>
          ))}
      </div>
    </div>
  );
});
