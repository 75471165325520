import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './HomePage.module.css';
import { Header } from '../../components/Header/Header';
import { Step } from '../../components/Step/Step';
import { StartButton } from '../../../ui/components/common/StartButton/StartButton';
import { useHistory } from 'react-router-dom';
import newPr from '../../../assets/images/newPr.png';
import edit from '../../../assets/images/edit.png';
import pres from '../../../assets/images/pres.png';
import { setDataFromXL, setIsChartsUpload, updateCharts } from '../../../bll/charts_reducer';
import { setError } from '../../../bll/app_reducer';
import * as XLSX from 'xlsx';
import Presentation from '../../../assets/files/AudioTron.pptx';

export const HomePage = React.memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isInitialized = useSelector(state => state.app.isInitialized);
  const onClickStartButton = () => {
    history.push('/step2');
  };
  const fileRef = React.createRef();
  const onClickUploadProject = () => {
    fileRef.current.click();
  };
  const onImportExcel = file => {
    const { files } = file.target;
    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: 'binary' });
        let data = [];
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
          }
        }

        dispatch(setDataFromXL(data));
        dispatch(updateCharts(data));
        dispatch(setIsChartsUpload(true));
        history.push('/step4');
      } catch (error) {
        dispatch(setError(error.message));
      }
    };
    fileReader.readAsBinaryString(files[0]);
  };

  useEffect(() => {
    if (!isInitialized) {
      history.push('/signin');
    }
  }, [isInitialized, history]);

  return (
    <div className={s.homeWrapper}>
      <Header />
      <div className={s.howItWorksBlock}>
        <h1>Как это работает</h1>
        <div className={s.steps}>
          <Step stepNumber={1} stepTitle={'Указываем вводные параметры'} stepDescription={'Параметры целевой аудитории,\n бюджет кампании,\n период кампании и частоту\n контакта'} />
          <Step stepNumber={2} stepTitle={'Выбираем поставщиков'} stepDescription={'Какие из предложенных\n вариантов поставщиков аудио\n размещений будут задействованы\n в кампании'} />
          <Step stepNumber={3} stepTitle={'Получаем рекомендации'} stepDescription={'Рекомендованное распределение\n бюджета в % по поставщикам на флайт\n с учетом медиапотребления аудитории,\n стоимостных вводных'} />
        </div>
      </div>
      <div className={s.startNewProjectBtnWrapper}>
        <a className={s.link} href={Presentation} download='AudioTron.pptx' style={{ marginRight: '50px' }}>
          <StartButton onClick={() => {}} redTitle={'Скачать'} blackTitle={'слайд\n об инструменте'}>
            <img src={pres} alt='newProgect' style={{ width: '40px', height: '40px' }} />
          </StartButton>
        </a>

        <div style={{ marginRight: '50px' }}>
          <StartButton onClick={onClickStartButton} redTitle={'Создать'} blackTitle={'новый проект'}>
            <img src={newPr} alt='newProgect' style={{ width: '40px', height: '40px' }} />
          </StartButton>
        </div>
        <div>
          <StartButton onClick={onClickUploadProject} redTitle={'Загрузить'} blackTitle={'сохраненный проект'}>
            <img src={edit} alt='newProgect' style={{ width: '40px', height: '40px' }} />
          </StartButton>
          <input type='file' accept='.xlsx, .xls' onChange={onImportExcel} ref={fileRef} style={{ display: 'none' }} />
        </div>
      </div>
    </div>
  );
});
