import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './PresenceOfAudioFlight.module.css';
import { Checkbox } from '../../../common/Checkbox/Checkbox';
import { setPresenceOfTvFlight, setIsCBUUpload, setCbu, setCbuData, setTv_rf } from '../../../../../bll/inputParameters_reducer';
import { handleInputDataPart1Changed } from '../../../../../helpers/utils';

export const PresenceOfAudioFlight = React.memo(props => {
  const { setAudio_Data_Download } = props;
  const dispatch = useDispatch();
  const presenceOfTvFlight = useSelector(state => state.input.presenceOfTvFlight);
  const cbuData = useSelector(state => state.input.cbuData);
  const handleOnChange = selectedName => {
    selectedName === 'audioFlightYes' ? dispatch(setPresenceOfTvFlight(1)) : dispatch(setPresenceOfTvFlight(0));
    selectedName === 'audioFlightNo' && dispatch(setIsCBUUpload(0));
    selectedName === 'audioFlightNo' && dispatch(setCbu(null));
    selectedName === 'audioFlightNo' && dispatch(setCbuData(null));
    selectedName === 'audioFlightNo' && dispatch(setTv_rf([]));
    handleInputDataPart1Changed(dispatch);
  };

  useEffect(() => {
    (presenceOfTvFlight === 1 && cbuData !== null) || (presenceOfTvFlight === 0 && cbuData === null) ? setAudio_Data_Download(true) : setAudio_Data_Download(false);
  }, [cbuData, presenceOfTvFlight, setAudio_Data_Download]);
  return (
    <div className={s.blockWrapper}>
      <div className={s.blockTitle}>
        НАЛИЧИЕ <br />
        РАДИО <br /> РАЗМЕЩЕНИЯ
      </div>
      <div className={s.blockContent}>
        <div className={s.checkboxWrapper}>
          <Checkbox
            onChangeChecked={() => {
              handleOnChange('audioFlightYes');
            }}
            checked={presenceOfTvFlight === 1 ? true : false}
            label={'Да'}
            id={'audioFlightYes'}
          />
        </div>
        <div className={s.checkboxWrapper}>
          <Checkbox
            onChangeChecked={() => {
              handleOnChange('audioFlightNo');
            }}
            checked={presenceOfTvFlight === 0 ? true : false}
            label={'Нет'}
            id={'audioFlightNo'}
          />
        </div>
      </div>
    </div>
  );
});
