import React, { useEffect } from 'react';
import s from './OptInvestmentChart.module.css';
import { Bar, Chart } from 'react-chartjs-2';
import { round, getMaxValueOfArray, getMinValueOfArray } from '../../../../helpers/chart_helpers';
import { setMillion } from '../../../../helpers/common_helpers';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(ChartDataLabels);

export const OptInvestmentChart = React.memo(props => {
  const { optInvestmentChart_data } = props;
  const growth_reachArr = optInvestmentChart_data.datasets && optInvestmentChart_data.datasets[0].data;
  const maxValueArr = optInvestmentChart_data.datasets && optInvestmentChart_data.datasets.filter(item => item.type === 'bar');
  const maxValue =
    maxValueArr &&
    maxValueArr.reduce(function (accumulator, currentValue) {
      return accumulator + +currentValue.data[8];
    }, 0);
  const visibleMaxValue = 5;
  const maxValuePr = (maxValue / 100) * visibleMaxValue; //вычисление процентов
  const optInvestmentChart_dataFiltered = optInvestmentChart_data.datasets && optInvestmentChart_data.datasets.filter(i => i.data.find(it => it !== 0));

  const options = {
    interaction: {
      mode: 'index',
      intersect: false,
    },
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 4,
      },
    },
    plugins: {
      datalabels: {
        display: 'true',
        formatter: function (value) {
          if (+value > maxValuePr) {
            const changedValue = setMillion(value);
            return changedValue;
          } else {
            return '';
          }
        },
        labels: {
          title: {
            font: {
              weight: 'bold',
              size: '14px',
            },
          },
        },
        anchor: 'centre',
        align: 'centre',
        color: 'white',
      },
      legend: {
        display: false,
        position: 'bottom',
        align: 'centre',
        usePointStyle: true,
      },
      tooltip: {
        enabled: true,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        titleColor: 'black',
        bodyColor: 'black',
      },
    },
    scales: {
      x: {
        stacked: true,
      },

      y: {
        stacked: true,
        title: {
          display: true,
          align: 'start',
          text: 'Затраты на медиа (млн)',
          color: 'grey',
          font: {
            size: 13,
          },

          padding: { top: 5, left: 0, right: 0, bottom: 0 },
        },
        ticks: {
          callback: function (val, index) {
            return round(setMillion(val), 1);
          },
        },
      },
      ohvat: {
        min: getMinValueOfArray(growth_reachArr),
        max: getMaxValueOfArray(growth_reachArr),
        position: 'right',
        stacked: true,
        grid: { lineWidth: 0 },
        title: {
          display: true,
          align: 'end',
          text: 'Совокупный охват',
          color: 'grey',
          font: {
            size: 13,
          },
          padding: { top: 5, left: 0, right: 0, bottom: 0 },
        },
        ticks: {
          callback: function (val, index) {
            return this.getLabelForValue(val) + '%';
          },
        },
      },
    },
  };
  useEffect(() => {
    optInvestmentChart_dataFiltered && optInvestmentChart_dataFiltered.map(item => (document.getElementById(item.label).style.backgroundColor = item.backgroundColor));
  }, [optInvestmentChart_data]);

  return (
    <div>
      <div className={s.wrapper}>
        <Bar data={optInvestmentChart_data} options={options} width={1000} height={210} />
      </div>
      <div className={s.lenendsBox}>
        {optInvestmentChart_dataFiltered &&
          optInvestmentChart_dataFiltered.map(item => (
            <div className={s.legendWrapper} key={item.label}>
              <div id={item.label} className={item.label === 'Охват' ? s.ohvatLegend : s.legend}></div> <span>{item.label}</span>
            </div>
          ))}
      </div>
    </div>
  );
});
