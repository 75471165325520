import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './Step4Page.module.css';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { RedButton } from '../../components/common/RedButton/RedButton';
import { SaveXLSOlv } from '../../components/SaveXLSOlv/SaveXLSOlv';
import { SaveXLSOlvTv } from '../../components/SaveXLSOlv_Tv/SaveXLSOlv_Tv';
import { SaveXLSOlvTvOpt } from '../../components/SaveXLSOlv_Tv_Opt/SaveXLSOlv_Tv_Opt';
import { SaveXLSOlvOpt } from '../../components/SaveXLSOlv_Opt/SaveXLSOlv_Opt';
import { Select } from '../../components/common/Select/Select';
import { DelimiterNew } from '../../components/common/DelimiterNew/DelimiterNew';
import { DelimiterOpt } from '../../components/common/DelimiterOpt/DelimiterOpt';
import { useHistory } from 'react-router-dom';
import { findValueView } from '../../../helpers/common_helpers';
import { round } from '../../../helpers/chart_helpers';
import { AudioDoughnutChart } from '../../components/Charts/AudioDoughnutChart/AudioDoughnutChart';
import { AudioContributionOfEachMedia } from '../../components/Charts/AudioContributionOfEachMedia/AudioContributionOfEachMedia';
import { AudioDistributionChart } from '../../components/Charts/AudioDistributionChart/AudioDistributionChart';
import { AudioCbuCprpChart } from '../../components/Charts/AudioCbuCprpChart/AudioCbuCprpChart';
import { RadioDistributionChart } from '../../components/Charts/RadioDistributionChart/RadioDistributionChart';
import { RadioOptimalSplitChart } from '../../components/Charts/RadioOptimalSplitChart/RadioOptimalSplitChart';
import { RadioOptimalDistributionChart } from '../../components/Charts/RadioOptimalDistributionChart/RadioOptimalDistributionChart';
import { RadioIncreaseAggregateCoverageChart } from '../../components/Charts/RadioIncreaseAggregateCoverageChart/RadioIncreaseAggregateCoverageChart';
import { ToggleSwitch } from '../../components/common/ToggleSwitch/TiggleSwitch';
import { setN_OLV, setN_TV, setFreqAll, setBudget_AudioCbuCprpChart, changeAudioContributionOfEachMedia, changeAudioDistributionChart, setBudgets_RadioIncreaseAggregateCoverageChart, setBudget_RadioDistributionChart, changeChartsOnChangeFreqAll, changeAudioCbuCprpChart, changeRadioDistributionChart, changeRadioDistributionChart_budget, changeRadioIncreaseAggregateCoverageChart } from '../../../bll/change_charts_reducer';
import { OptInvestmentChart } from '../../components/Charts/OptInvestmentChart/OptInvestmentChart';
import { OptComparisonChart } from '../../components/Charts/OptComparisonChart/OptComparisonChart';
import { EditableSpan } from '../../components/common/EditableSpan/EditableSpan';
import { ExportPPTOlv } from '../../components/ExportPPTOlv/ExportPPTOlv';
import { ExportPPTOlvOpt } from '../../components/ExportPPTOlvOpt/ExportPPTOlvOpt';
import { ExportPPTOlvTv } from '../../components/ExportPPTOlvTv/ExportPPTOlvTv';
import { ExportPPTOlvTvOpt } from '../../components/ExportPPTOlvTvOpt/ExportPPTOlvTvOpt';

export const Step4Page = React.memo(props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const universe = useSelector(state => state.table.universe);
  const chartsData = useSelector(state => state.charts.chartsData);
  const chartsData_TV_OLV_RFdistr2 = useSelector(state => state.charts.chartsData_TV_OLV_RFdistr2);
  const chartsData_TV_OLV_20_view = useSelector(state => state.charts.chartsData_TV_OLV_20_view);
  const share = useSelector(state => state.table.share);
  const mainMedia = useSelector(state => state.change.mainMedia);
  const isOptimization = useSelector(state => state.opt.isOptimization);
  const isCBUUpload = useSelector(state => state.input.isCBUUpload);
  const mainMediaArr = useSelector(state => state.change.mainMediaArr);
  const N_OLV = useSelector(state => state.change.N_OLV);
  const N_TV = useSelector(state => state.change.N_TV);
  const freqAll = useSelector(state => state.change.freqAll);
  const budget_AudioCbuCprpChart = useSelector(state => state.change.budget_AudioCbuCprpChart);
  const budget_RadioDistributionChart = useSelector(state => state.change.budget_RadioDistributionChart);
  const budgets_RadioIncreaseAggregateCoverageChart = useSelector(state => state.change.budgets_RadioIncreaseAggregateCoverageChart);
  const isChartsUpload = useSelector(state => state.charts.isChartsUpload);
  const cbu = useSelector(state => state.input.cbu);
  const cpp = useSelector(state => state.table.cpp);
  const dataFromXL = useSelector(state => state.charts.dataFromXL);

  const RadioOptimalSplitChart_data = useSelector(state => state.charts.RadioOptimalSplitChart_data);
  const RadioOptimalDistributionChart_data = useSelector(state => state.charts.RadioOptimalDistributionChart_data);
  const RadioIncreaseAggregateCoverageChart_data = useSelector(state => state.charts.RadioIncreaseAggregateCoverageChart_data);
  const RadioDistributionChart_data = useSelector(state => state.charts.RadioDistributionChart_data);
  const optInvestmentChart_data = useSelector(state => state.charts.optInvestmentChart_data);
  const optComparisonChart_data = useSelector(state => state.charts.optComparisonChart_data);
  const olvDonutData = useSelector(state => state.charts.olvDonutData);
  const olvDistribution_data = useSelector(state => state.charts.olvDistribution_data);
  const AudioContributionOfEachMedia_data = useSelector(state => state.charts.AudioContributionOfEachMedia_data);
  const AudioCbuCprpChartData = useSelector(state => state.charts.AudioCbuCprpChartData);

  const [isExportPPT, setIsExportPPT] = useState(false);
  const onClickExportPPT = () => {
    setIsExportPPT(true);
  };
  const findTV_budget_values = () => {
    return cbu.map(item =>
      round(item[0] * cpp)
        .toString()
        .replace(/(\d)(?=(\d{3})+$)/g, '$1 ')
    );
  };
  const findTV_budget_value_view = value => {
    return value.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
  };

  const onClick_OLV_ToggleSwitch = e => {
    e.currentTarget.checked === true ? dispatch(setN_OLV(1)) : dispatch(setN_OLV(0));
    dispatch(changeAudioDistributionChart(e.currentTarget.checked ? 1 : 0));
    setIsExportPPT(false);
  };
  const onClick_TV_ToggleSwitch = e => {
    e.currentTarget.checked === true ? dispatch(setN_TV(1)) : dispatch(setN_TV(0));
    dispatch(changeRadioDistributionChart(e.currentTarget.checked ? 1 : 0));
    setIsExportPPT(false);
  };
  const onClickBackButton = () => {
    history.push('/step3');
  };
  const onChangeFreqAll = selectItem => {
    dispatch(setFreqAll(selectItem));
    dispatch(changeChartsOnChangeFreqAll(selectItem));
    setIsExportPPT(false);
  };
  const onChange_budget_AudioCbuCprpChart = newValue => {
    dispatch(setBudget_AudioCbuCprpChart(newValue));
    dispatch(changeAudioCbuCprpChart(newValue));
    setIsExportPPT(false);
  };
  const onChangeMainMedia = selectItem => {
    dispatch(changeAudioContributionOfEachMedia(selectItem));
    setIsExportPPT(false);
  };
  const onChange_olv_budget_RadioIncreaseAggregateCoverageChart = newValue => {
    const updatedBudgetState = { ...budgets_RadioIncreaseAggregateCoverageChart };
    updatedBudgetState.olvBudget = newValue;
    dispatch(setBudgets_RadioIncreaseAggregateCoverageChart(updatedBudgetState));
    dispatch(changeRadioIncreaseAggregateCoverageChart(budgets_RadioIncreaseAggregateCoverageChart.tvBudget, newValue));
    setIsExportPPT(false);
  };
  const onChange_tv_budget_RadioIncreaseAggregateCoverageChart = selectItem => {
    const selectItemNumber = selectItem.replace(/\s/g, '');
    const updatedBudgetState = { ...budgets_RadioIncreaseAggregateCoverageChart };
    updatedBudgetState.tvBudget = +selectItemNumber;
    dispatch(setBudgets_RadioIncreaseAggregateCoverageChart(updatedBudgetState));
    dispatch(changeRadioIncreaseAggregateCoverageChart(+selectItemNumber, budgets_RadioIncreaseAggregateCoverageChart.olvBudget));
    setIsExportPPT(false);
  };
  const onChange_budget_RadioDistributionChart = selectItem => {
    const selectItemNumber = selectItem.replace(/\s/g, '');
    dispatch(setBudget_RadioDistributionChart(+selectItemNumber));
    dispatch(changeRadioDistributionChart_budget(+selectItemNumber));
    setIsExportPPT(false);
  };

  const chooseExportPPT = () => {
    if (isCBUUpload === 0 && isOptimization === -1) {
      return <ExportPPTOlv />;
    } else if (isCBUUpload === 0 && isOptimization > -1) {
      return <ExportPPTOlvOpt />;
    } else if (isCBUUpload === 1 && isOptimization === -1) {
      return <ExportPPTOlvTv />;
    } else if (isCBUUpload === 1 && isOptimization > -1) {
      return <ExportPPTOlvTvOpt />;
    }
  };
  const isInitialized = useSelector(state => state.app.isInitialized);
  useEffect(() => {
    if (!isInitialized) {
      history.push('/signin');
    }
  }, [isInitialized, history]);

  return (
    <div className={s.step4Wrapper}>
      <div className={s.fixedBlock}>
        <PageHeader pageHeaderTitle={'Результат'} pageHeaderNumber={'4.'} withRedTitle />
        <div className={s.step4Content}>
          <div className={s.buttonsWrapper}>
            <div className={s.leftPartWrapper}>
              <RedButton onClick={onClickBackButton} title={'Вернуться назад'} />
              <RedButton onClick={onClickExportPPT} title={'Выгрузить ppt'} />

              {!isChartsUpload && isOptimization === -1 && isCBUUpload === 1 && chartsData.tv_olv_data && Object.keys(chartsData_TV_OLV_RFdistr2).length > 0 && Object.keys(chartsData_TV_OLV_20_view).length > 0 && (
                <div>
                  <SaveXLSOlvTv chartsData={chartsData} chartsData_TV_OLV_RFdistr2={chartsData_TV_OLV_RFdistr2} chartsData_TV_OLV_20_view={chartsData_TV_OLV_20_view} />
                </div>
              )}

              {!isChartsUpload && isOptimization > -1 && isCBUUpload === 1 && chartsData.bud_parts && chartsData.tv_olv_data && Object.keys(chartsData_TV_OLV_RFdistr2).length > 0 && Object.keys(chartsData_TV_OLV_20_view).length > 0 && (
                <div>
                  <SaveXLSOlvTvOpt chartsData={chartsData} chartsData_TV_OLV_RFdistr2={chartsData_TV_OLV_RFdistr2} chartsData_TV_OLV_20_view={chartsData_TV_OLV_20_view} />
                </div>
              )}
              {!isChartsUpload && isOptimization === -1 && isCBUUpload === 0 && Object.keys(chartsData).length > 0 && (
                <div>
                  <SaveXLSOlv chartsData={chartsData} />
                </div>
              )}

              {!isChartsUpload && isOptimization > -1 && isCBUUpload === 0 && chartsData.bud_parts && (
                <div>
                  <SaveXLSOlvOpt chartsData={chartsData} />
                </div>
              )}

              {isChartsUpload && isOptimization === -1 && isCBUUpload === 1 && (
                <div>
                  <SaveXLSOlvTv chartsData={chartsData} chartsData_TV_OLV_RFdistr2={chartsData_TV_OLV_RFdistr2} chartsData_TV_OLV_20_view={chartsData_TV_OLV_20_view} />
                </div>
              )}

              {isChartsUpload && isOptimization > -1 && isCBUUpload === 1 && (
                <div>
                  <SaveXLSOlvTvOpt chartsData={chartsData} chartsData_TV_OLV_RFdistr2={chartsData_TV_OLV_RFdistr2} chartsData_TV_OLV_20_view={chartsData_TV_OLV_20_view} />
                </div>
              )}
              {isChartsUpload && isOptimization === -1 && isCBUUpload === 0 && (
                <div>
                  <SaveXLSOlv chartsData={chartsData} />
                </div>
              )}

              {isChartsUpload && isOptimization > -1 && isCBUUpload === 0 && (
                <div>
                  <SaveXLSOlvOpt chartsData={chartsData} />
                </div>
              )}
            </div>
            <div className={s.rightPartWrapper}>
              <div className={s.frequencyWrapper}>
                <div>
                  Частота, на которую <br /> смотрим результаты
                </div>
                {freqAll !== null && <Select value={freqAll} onChange={onChangeFreqAll} items={['1 +', '2 +', '3 +', '4 +', '5 +', '6 +', '7 +', '8 +', '9 +', '10 +', '11 +', '12 +', '13 +', '14 +', '15 +', '16 +', '17 +', '18 +', '19 +', '20 +']} />}
              </div>
              <div className={s.universeWrapper}>
                <div>Universe: {findValueView(universe, 'Universe')}</div>
                <div>Share: {findValueView(share, 'Share')} </div>
              </div>
            </div>
          </div>
        </div>
        {isExportPPT && chooseExportPPT()}
      </div>

      <div className={s.chartsWrapper}>
        <DelimiterNew title={'Результат планирования рекламного размещения Digital Audio'} />

        <div className={s.twoChartsWrapper}>
          <div className={s.oneChartWrapper}>
            <h4 className={s.chartTitle}>Вклад каждой площадки в совокупный охват на частоте {freqAll}</h4>
            <div className={s.chooseMainMediaBlockWrapper}>
              <p className={s.selectTitle}>Основная площадка: </p>
              {mainMedia !== null && (
                <div className={s.selectWrapper}>
                  <Select value={mainMedia} onChange={onChangeMainMedia} items={mainMediaArr} location={'contr'} />
                </div>
              )}
            </div>
            {Object.keys(AudioContributionOfEachMedia_data).length > 0 && <AudioContributionOfEachMedia AudioContributionOfEachMedia_data={AudioContributionOfEachMedia_data} />}
          </div>
          <div className={s.oneChartWrapper}>
            <h4 className={s.chartTitle}>Распределение бюджета по площадкам (млн. ₽)</h4>
            {Object.keys(AudioCbuCprpChartData).length > 0 && <AudioDoughnutChart olvDonutData={olvDonutData} AudioCbuCprpChartData={AudioCbuCprpChartData} />}
          </div>
        </div>

        <div className={s.twoChartsWrapper}>
          <div className={s.oneChartWrapper}>
            <div className={s.toggleSwitchWrapper}>
              <h4 className={s.chartTitle}>Распределение Reach / Frequency (Audio)</h4>
              <ToggleSwitch
                onChangeChecked={e => {
                  onClick_OLV_ToggleSwitch(e);
                }}
                checked={N_OLV === 1 ? true : false}
                labelStart={'N+'}
                labelEnd={'N'}
                id={'toggle_switch_distribution'}
              />
            </div>

            {Object.keys(olvDistribution_data).length > 0 && <AudioDistributionChart olvDistribution_data={olvDistribution_data} />}
          </div>
          <div className={s.oneChartWrapper}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h4 className={s.chartTitle}>Audio: CBU & CPRP на частоте {freqAll}</h4>
              <EditableSpan value={budget_AudioCbuCprpChart} name={'budget_AudioCbuCprpChart'} onChange={onChange_budget_AudioCbuCprpChart} variable={'PopulationCityX'} location={'budget'} borderTitle={'Audio Бюджет'} />
            </div>

            {Object.keys(AudioCbuCprpChartData).length > 0 && <AudioCbuCprpChart freqAll={freqAll} AudioCbuCprpChartData={AudioCbuCprpChartData} />}
          </div>
        </div>

        {isOptimization > -1 && (
          <>
            <DelimiterOpt title={`Рекомендации для Digital Audio размещения по итогу использования функции оптимизации бюджета`} />

            <div className={s.twoChartsWrapper}>
              <div className={s.oneChartWrapper}>
                <h4 className={s.chartTitle}>Влияние прироста инвестиций в рекламу на микс площадок и совокупный охват (на заданную частоту на шаге #3 при использовании функции “Оптимизировать Digital audio бюджет”)</h4>
                {Object.keys(optInvestmentChart_data).length > 0 && <OptInvestmentChart optInvestmentChart_data={optInvestmentChart_data} />}
              </div>
              <div className={s.oneChartWrapper}>
                <h4 className={s.chartTitle}>Сравнение различных миксов площадок на идентичный бюджет и их влияние на совокупный охват (на заданную частоту на шаге #3 при использовании функции “Оптимизировать Digital audio бюджет”)</h4>
                {Object.keys(optComparisonChart_data).length > 0 && <OptComparisonChart optComparisonChart_data={optComparisonChart_data} />}
              </div>
            </div>
          </>
        )}

        {isCBUUpload === 1 && (
          <>
            <DelimiterNew title={'Результат планирования рекламного размещения Radio & Digital Audio'} />
            <div className={s.twoChartsWrapper}>
              <div className={s.oneChartWrapper}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h4 className={s.chartTitle}>Распределение Reach / Frequency (Radio & Audio)</h4>
                  <div style={{ display: 'flex' }}>
                    {budget_RadioDistributionChart !== null && <Select value={findTV_budget_value_view(budget_RadioDistributionChart)} onChange={onChange_budget_RadioDistributionChart} items={findTV_budget_values()} borderTitle={'Radio Бюджет'} location={'chart'} />}
                    <ToggleSwitch
                      onChangeChecked={e => {
                        onClick_TV_ToggleSwitch(e);
                      }}
                      checked={N_TV === 1 ? true : false}
                      labelStart={'N+'}
                      labelEnd={'N'}
                      id={'toggle_switch_RadioDistributionChart'}
                    />
                  </div>
                </div>

                {Object.keys(RadioDistributionChart_data).length > 0 && <RadioDistributionChart RadioDistributionChart_data={RadioDistributionChart_data} />}
              </div>
              {cbu !== null && (
                <div className={s.oneChartWrapper}>
                  <h4 className={s.chartTitle}>Оптимальный сплит бюджета Radio & Audio</h4>
                  {Object.keys(RadioOptimalSplitChart_data).length > 0 && <RadioOptimalSplitChart cbu={cbu} cpp={cpp} RadioOptimalSplitChart_data={RadioOptimalSplitChart_data} />}
                </div>
              )}
            </div>
            <div className={s.twoChartsWrapper}>
              <div className={s.oneChartWrapper}>
                <h4 className={s.chartTitle}>Оптимальное распределение бюджета для достижения максимального совокупного охвата при нефиксированных Radio и Audio бюджетах</h4>
                <div style={{ marginTop: '20px' }}>{Object.keys(RadioOptimalDistributionChart_data).length > 0 && <RadioOptimalDistributionChart chartsData={chartsData} freqAll={freqAll} isChartsUpload={isChartsUpload} dataFromXL={dataFromXL} RadioOptimalDistributionChart_data={RadioOptimalDistributionChart_data} />}</div>
              </div>
              <div className={s.oneChartWrapper} style={{ position: 'relative' }}>
                <h4 className={s.chartTitle}>Рост совокупного охвата Radio & Audio при фиксированном бюджете Radio и нефиксированном бюджете Audio</h4>
                <div style={{ display: 'flex', justifyContent: 'end', position: 'absolute', top: '10%', left: '50%' }}>
                  {budgets_RadioIncreaseAggregateCoverageChart.tvBudget !== null && <Select value={findTV_budget_value_view(budgets_RadioIncreaseAggregateCoverageChart.tvBudget)} onChange={onChange_tv_budget_RadioIncreaseAggregateCoverageChart} items={findTV_budget_values()} borderTitle={'Radio Бюджет'} location={'chart'} />}
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <EditableSpan value={budgets_RadioIncreaseAggregateCoverageChart.olvBudget} name={'budgets_RadioIncreaseAggregateCoverageChart.olvBudget'} onChange={onChange_olv_budget_RadioIncreaseAggregateCoverageChart} variable={'PopulationCityX'} location={'budget'} borderTitle={'Audio Бюджет'} />
                  </div>
                </div>
                <div style={{ marginTop: '20px' }}>{Object.keys(RadioIncreaseAggregateCoverageChart_data).length > 0 && <RadioIncreaseAggregateCoverageChart RadioIncreaseAggregateCoverageChart_data={RadioIncreaseAggregateCoverageChart_data} />}</div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
});
