import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './Geo.module.css';
import { Checkbox } from '../../../common/Checkbox/Checkbox';
import { setGeo } from '../../../../../bll/inputParameters_reducer';
import { EditableSpan } from '../../../common/EditableSpan/EditableSpan';
import { handleInputDataPart1Changed } from '../../../../../helpers/utils';

export const Geo = React.memo(props => {
  const { setGeoCorrect } = props;
  const dispatch = useDispatch();
  const geo = useSelector(state => state.input.geo);

  const handleOnChange = selectedName => {
    const updatedGenderState = geo.map(item => {
      const selectedItem = { ...item };
      selectedItem.isChecked = true;
      const otherItem = { ...item };
      otherItem.isChecked = false;
      return item.name === selectedName ? selectedItem : otherItem;
    });
    dispatch(setGeo(updatedGenderState));
    handleInputDataPart1Changed(dispatch);
  };
  const onChangePopulation = newValue => {
    const updatedGeoState = geo.map(item => {
      const updatedItem = { ...item };
      updatedItem.population = newValue;
      const otherItem = { ...item };
      return item.name === 'cityХ' ? updatedItem : otherItem;
    });
    dispatch(setGeo(updatedGeoState));
    handleInputDataPart1Changed(dispatch);
  };
  useEffect(() => {
    (geo[3].isChecked && geo[3].population !== '' && geo[3].population > 10000) || !geo[3].isChecked ? setGeoCorrect(true) : setGeoCorrect(false);
  }, [geo, setGeoCorrect]);
  return (
    <div className={s.blockWrapper}>
      <div className={s.blockTitle}>ГЕО</div>
      <div className={s.blockContent}>
        <div className={s.checkboxWrapper}>
          <Checkbox
            onChangeChecked={() => {
              handleOnChange('NAT');
            }}
            checked={geo[0].isChecked}
            label={'РФ 0+'}
            id={'national'}
          />
        </div>
        <div className={s.checkboxWrapper}>
          <Checkbox
            onChangeChecked={() => {
              handleOnChange('Moscow');
            }}
            checked={geo[1].isChecked}
            label={'Москва'}
            id={'Moscow'}
          />
        </div>
        <div className={s.checkboxWrapper}>
          <Checkbox
            onChangeChecked={() => {
              handleOnChange('Petersburg');
            }}
            checked={geo[2].isChecked}
            label={'Санкт-Петербург'}
            id={'Petersburg'}
          />
        </div>
        <div className={s.checkboxWrapper}>
          <Checkbox
            onChangeChecked={() => {
              handleOnChange('cityХ');
            }}
            checked={geo[3].isChecked}
            label={'Другое, указать\n численность населения'}
            id={'cityХ'}
          />
          {geo[3].isChecked && <EditableSpan value={geo[3].population} name={'populationCityX'} onChange={onChangePopulation} variable={'PopulationCityX'} location={'PopulationCityX'} />}
        </div>
      </div>
    </div>
  );
});
