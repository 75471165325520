import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './DownloadCBU.module.css';
import { DownloadButton } from '../../../common/DownloadButton/DownloadButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheck } from '@fortawesome/free-solid-svg-icons';
import SampleCBU from '../../../../../assets/sample/Радио для аудиотрона.xlsx';
import SuperNova from '../../../../../assets/sample/Темплейт SuperNova для Аудиотрона.pln';
import * as XLSX from 'xlsx';
import { setIsCBUUpload, setCbuData } from '../../../../../bll/inputParameters_reducer';
import { setError } from '../../../../../bll/app_reducer';
import { handleInputDataPart1Changed } from '../../../../../helpers/utils';

export const DownloadCBU = React.memo(props => {
  const dispatch = useDispatch();
  const presenceOfTvFlight = useSelector(state => state.input.presenceOfTvFlight);
  const isCBUUpload = useSelector(state => state.input.isCBUUpload);
  const fileRef = React.createRef();

  const onClickUploadCBU = () => {
    fileRef.current.click();
  };

  const onImportExcel = file => {
    const { files } = file.target;
    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: 'binary' });
        let data = [];
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
          }
        }
        const valuesArrData = data.map(i => Object.values(i));
        const reachArrData = valuesArrData.find(i => i.indexOf('Reach%') > -1);
        const TRPArrData = valuesArrData.find(i => i.indexOf('TRP') > -1);
        const cbuData = {
          trp1: +TRPArrData[1],
          reach1: +reachArrData[1],
          trp2: +TRPArrData[TRPArrData.length - 1],
          reach2: +reachArrData[reachArrData.length - 1],
          CPP: 28571,
        };
        dispatch(setCbuData(cbuData));
        dispatch(setIsCBUUpload(1));
        handleInputDataPart1Changed(dispatch);
      } catch (error) {
        dispatch(setError(error.message));
      }
    };
    fileReader.readAsBinaryString(files[0]);
  };
  return (
    <div className={s.blockWrapper}>
      <div className={s.blockTitle}>
        ЗАГРУЗИТЬ <br /> ДАННЫЕ
      </div>
      <div className={s.blockContent}>
        <div className={s.blockDownloadRadio}>
          <DownloadButton onClick={onClickUploadCBU} isDisabled={presenceOfTvFlight === 0 ? true : false}>
            {isCBUUpload === 1 ? (
              <div style={{ color: 'green' }}>
                <FontAwesomeIcon icon={faCheck} />
              </div>
            ) : (
              <FontAwesomeIcon icon={faPlus} />
            )}
          </DownloadButton>
          <input type='file' accept='.xlsx, .xls' onChange={onImportExcel} ref={fileRef} style={{ display: 'none' }} />
          <div className={s.linkWrapper}>
            Образец файла
            <br /> для загрузки в
            <br />
            AudioTron{' '}
            <a className={s.link} href={SampleCBU} download='Радио для аудиотрона.xlsx'>
              здесь
            </a>
          </div>
        </div>
        <div>
          <div className={s.superNovaWrapper}>
            Темплейт для
            <br /> SuperNova
            <br />
            <a className={s.link} href={SuperNova} download='Темплейт SuperNova для Аудиотрона.pln'>
              здесь
            </a>
          </div>
        </div>
      </div>
    </div>
  );
});
