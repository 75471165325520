import React from 'react';
import s from './RadioOptimalDistributionChart.module.css';
import { Line } from 'react-chartjs-2';
import { round, getFreqNumber } from '../../../../helpers/chart_helpers';
import { setMillion } from '../../../../helpers/common_helpers';

export const RadioOptimalDistributionChart = React.memo(props => {
  const { chartsData, freqAll, dataFromXL, isChartsUpload, RadioOptimalDistributionChart_data } = props;

  const takeDataForTooltip_dataFromXL = () => {
    const freqPost = getFreqNumber(freqAll);
    const TV_OPT_DISTR_DataFromXL = dataFromXL.filter(item => item.TV_OPT_DISTR && item.Freq === freqPost);
    const TV_OPT_DISTR_olv_bud_DataFromXL = TV_OPT_DISTR_DataFromXL.filter(item => item.Variable === 'Digital_audio_bud')[0];
    const TV_OPT_DISTR_tv_bud_DataFromXL = TV_OPT_DISTR_DataFromXL.filter(item => item.Variable === 'Radio_bud')[0];
    const TV_OPT_DISTR_full_bud_DataFromXL = TV_OPT_DISTR_DataFromXL.filter(item => item.Variable === 'full_bud')[0];
    const labelsArr_DataFromXL = Object.keys(TV_OPT_DISTR_olv_bud_DataFromXL);
    return [Object.values(TV_OPT_DISTR_olv_bud_DataFromXL), Object.values(TV_OPT_DISTR_tv_bud_DataFromXL), Object.values(TV_OPT_DISTR_full_bud_DataFromXL), labelsArr_DataFromXL];
  };

  const takeDataForTooltip_chartsData = () => {
    const freqPost = getFreqNumber(freqAll);
    const TV_OPT_DISTR = chartsData.tv_olv_data.opt_bud[freqPost];
    const TV_OPT_DISTR_olv_bud = TV_OPT_DISTR.olv_bud;
    const TV_OPT_DISTR_tv_bud = TV_OPT_DISTR.tv_bud;
    const TV_OPT_DISTR_full_bud = TV_OPT_DISTR.full_bud;
    const labelsArr = Object.keys(TV_OPT_DISTR_olv_bud);
    return [Object.values(TV_OPT_DISTR_olv_bud), Object.values(TV_OPT_DISTR_tv_bud), Object.values(TV_OPT_DISTR_full_bud), labelsArr];
  };

  const options = {
    interaction: {
      mode: 'index',
      intersect: false,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: true,
        position: 'bottom',
        align: 'centre',
        labels: {
          boxHeight: 0.5,
        },
      },
      tooltip: {
        enabled: true,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        titleColor: 'black',
        bodyColor: 'black',
        boxHeight: 1,
        callbacks: {
          label: function (TooltipItem) {
            return TooltipItem.dataset.label + ': ' + round(TooltipItem.parsed.y, 1) + ` %`;
          },
          title: function (TooltipItem) {
            return '';
          },
          afterBody: function (TooltipItem) {
            const labelsArr = isChartsUpload ? takeDataForTooltip_dataFromXL()[3].map(i => String(setMillion(i))) : takeDataForTooltip_chartsData()[3].map(i => String(round(+i / 10, 2)));
            const currentValue = String(+TooltipItem[0].label);
            const findCurrentValue_ChartsData = labelsArr.find(item => String(item).indexOf(currentValue) > -1);
            const findCurrentValue_Ind = labelsArr.indexOf(findCurrentValue_ChartsData);
            const findCurrentValue_dataFromXL = labelsArr.find(item => String(item).indexOf(currentValue) > -1);
            const findCurrentValue_dataFromXLInd = labelsArr.indexOf(findCurrentValue_dataFromXL);
            return [`Опт. бюджет Audio: ${isChartsUpload ? setMillion(takeDataForTooltip_dataFromXL()[0][findCurrentValue_dataFromXLInd]) : setMillion(takeDataForTooltip_chartsData()[0][findCurrentValue_Ind])} млн`, `Опт. бюджет Radio: ${isChartsUpload ? setMillion(takeDataForTooltip_dataFromXL()[1][findCurrentValue_dataFromXLInd]) : setMillion(takeDataForTooltip_chartsData()[1][findCurrentValue_Ind])} млн`, `Общий бюджет: ${isChartsUpload ? setMillion(takeDataForTooltip_dataFromXL()[2][findCurrentValue_dataFromXLInd]) : setMillion(takeDataForTooltip_chartsData()[2][findCurrentValue_Ind])} млн`];
          },
        },
      },
    },

    scales: {
      y: {
        title: {
          display: true,
          align: 'start',
          text: 'Reach %',
          color: 'grey',
          font: {
            size: 13,
          },
          padding: { top: 5, left: 0, right: 0, bottom: 0 },
        },

        ticks: {
          callback: function (val, index) {
            return this.getLabelForValue(val) + '%';
          },
        },
      },
      x: {
        title: {
          display: true,
          align: 'start',
          text: 'Бюджет (млн. руб)',
          color: 'grey',
          font: {
            size: 13,
          },
          padding: 0,
        },
      },
    },
  };
  return (
    <div className={s.wrapper}>
      <Line width={300} height={220} data={RadioOptimalDistributionChart_data} options={options} />
    </div>
  );
});
