import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './App.css';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { HomePage } from './ui/pages/HomePage/HomePage';
import { Step2Page } from './ui/pages/Step2Page/Step2Page';
import { Step3Page } from './ui/pages/Step3Page/Step3Page';
import { Step4Page } from './ui/pages/Step4Page/Step4Page';
import { InstructionPage } from './ui/pages/InstructionPage/InstructionPage';
import { SigninPage } from './ui/pages/SigninPage/SigninPage';
import { Progress } from './ui/components/common/Progress/Progress';
import { Warning } from './ui/components/common/Warning/Warning';
import { getStartIp } from './bll/app_reducer';

export const App = () => {
  const [errorWarning, setErrorWarning] = useState(false);
  const dispatch = useDispatch();
  const status = useSelector(state => state.app.status);
  const error = useSelector(state => state.app.error);
  const isInitialized = useSelector(state => state.app.isInitialized);

  const history = useHistory();
  useEffect(() => {
    dispatch(getStartIp());
  }, [dispatch]);

  useEffect(() => {
    error !== null && setErrorWarning(true);
  }, [error]);

  // useEffect(() => {
  //   const data = JSON.parse(localStorage.getItem('app'));
  //   const maxData = data && data.timestamp + 86400000;
  //   if (Date.now() < maxData) {
  //     dispatch(setInitialized(true));
  //   } else if (Date.now() > maxData) {
  //     dispatch(setInitialized(false));
  //     localStorage.removeItem('app');
  //   }
  // }, [dispatch]);
  useEffect(() => {
    if (!isInitialized) {
      history.push('/signin');
    }
  }, [history, isInitialized]);

  return (
    <div className='App'>
      {status === 'loading' && <Progress />}
      <Warning text={error} warningVisible={errorWarning} setWarningVisible={setErrorWarning} />
      <Switch>
        <Route exact path={'/'} render={() => <HomePage />} />
        <Route exact path={'/step2'} render={() => <Step2Page />} />
        <Route exact path={'/step3'} render={() => <Step3Page />} />
        <Route exact path={'/step4'} render={() => <Step4Page />} />
        <Route exact path={'/instruction'} render={() => <InstructionPage />} />
        <Route exact path={'/signin'} render={() => <SigninPage />} />
        <Route path={'/404'} render={() => <h1>404: PAGE NOT FOUND</h1>} />
        <Redirect from={'*'} to={'/'}></Redirect>
      </Switch>
    </div>
  );
};
