import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './Age.module.css';
import { InputNumber } from '../../../common/InputNumber/InputNumber';
import { setAgemin, setAgemax } from '../../../../../bll/inputParameters_reducer';
import { handleInputDataPart1Changed } from '../../../../../helpers/utils';

export const Age = React.memo(props => {
  const { setAgeminIsSelected, setAgemaxIsSelected } = props;
  const dispatch = useDispatch();
  const agemin = useSelector(state => state.input.agemin);
  const agemax = useSelector(state => state.input.agemax);

  const onChangeAgemin = e => {
    dispatch(setAgemin(+e.currentTarget.value));
    handleInputDataPart1Changed(dispatch);
  };
  const onChangeAgemax = e => {
    dispatch(setAgemax(+e.currentTarget.value));
    handleInputDataPart1Changed(dispatch);
  };
  useEffect(() => {
    agemin ? setAgeminIsSelected(true) : setAgeminIsSelected(false);
  }, [agemin, setAgeminIsSelected]);
  useEffect(() => {
    agemax ? setAgemaxIsSelected(true) : setAgemaxIsSelected(false);
  }, [agemax, setAgemaxIsSelected]);
  return (
    <div className={s.blockWrapper}>
      <div className={s.blockTitle}>ВОЗРАСТ</div>
      <div className={s.blockContent}>
        <p className={s.p}>ОТ (ВКЛЮЧАЯ)</p>
        <InputNumber value={agemin} onChange={onChangeAgemin} />
        <p className={s.p}>ДО (ВКЛЮЧАЯ)</p>
        <InputNumber value={agemax} onChange={onChangeAgemax} />
      </div>
    </div>
  );
});
