import React from 'react';
import s from './StartButton.module.css';

export const StartButton = React.memo(props => {
  const { onClick, redTitle, blackTitle, children } = props;
  return (
    <div>
      <button className={s.startButton} onClick={onClick}>
        <div className={s.icon}>{children}</div>
        <div className={s.title}>
          <div className={s.redTitle}>{redTitle}</div>
          <div>{blackTitle}</div>
        </div>
      </button>
    </div>
  );
});
