import { API } from '../dal/api';
import { setStatus, setError } from './app_reducer';
import { setIsOptimization, setOptimizationData } from './optimization_reducer';
import { setIsInputDataPart1Changed } from './inputParameters_reducer';
import { setMainMedia, setFreqAll, setBudget_RadioDistributionChart, setBudgets_RadioIncreaseAggregateCoverageChart } from './change_charts_reducer';

const SET_PUBLISHERS = 'SET_PUBLISHERS ';
const SET_IMP_CHANGES_NOT_POSSIBLE = 'SET_IMP_CHANGES_NOT_POSSIBLE';
const SET_BGT_CHANGES_NOT_POSSIBLE = 'SET_BGT_CHANGES_NOT_POSSIBLE';
const SET_CRM_CHANGES_NOT_POSSIBLE = 'SET_CRM_CHANGES_NOT_POSSIBLE ';
const SET_IS_CALCULATED = 'SET_IS_CALCULATED';
const SET_CPP = 'SET_CPP';
const SET_UNIVERSE = 'SET_UNIVERSE';
const SET_SHARE = 'SET_SHARE';
const SET_TOTAL = 'SET_TOTAL';
const SET_IS_CPP = 'SET_IS_CPP';
const SET_CPT = 'SET_CPT';

const initialState = {
  publishers: [],
  impChangesNotPossible: 0,
  bgtChangesNotPossible: 0,
  crmChangesNotPossible: 1,
  isCalculated: false,
  cpp: 28571,
  universe: 0,
  share: 0,
  total: [{ total_Budget: 0, total_Impressions: 0, total_CPM: 0, total_Tsa_thousand: 0, total_Tsa_percent: 0, total_TRP: 0 }],
  isCpp: 0,
  cpt: 75,
};

export const publishersTable_reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PUBLISHERS:
      return { ...state, publishers: action.publishers };
    case SET_IMP_CHANGES_NOT_POSSIBLE:
      return { ...state, impChangesNotPossible: action.impChangesNotPossible };
    case SET_BGT_CHANGES_NOT_POSSIBLE:
      return { ...state, bgtChangesNotPossible: action.bgtChangesNotPossible };
    case SET_CRM_CHANGES_NOT_POSSIBLE:
      return { ...state, crmChangesNotPossible: action.crmChangesNotPossible };
    case SET_IS_CALCULATED:
      return { ...state, mediaIsSelected: action.mediaIsSelected };
    case SET_CPP:
      return { ...state, cpp: action.cpp };
    case SET_UNIVERSE:
      return { ...state, universe: action.universe };
    case SET_SHARE:
      return { ...state, share: action.share };
    case SET_TOTAL:
      return { ...state, total: action.total };
    case SET_IS_CPP:
      return { ...state, isCpp: action.isCpp };
    case SET_CPT:
      return { ...state, cpt: action.cpt };
    default:
      return state;
  }
};

// action creators
export const setPublishers = publishers => ({ type: SET_PUBLISHERS, publishers });
export const setImpChangesNotPossible = impChangesNotPossible => ({ type: SET_IMP_CHANGES_NOT_POSSIBLE, impChangesNotPossible });
export const setBgtChangesNotPossible = bgtChangesNotPossible => ({ type: SET_BGT_CHANGES_NOT_POSSIBLE, bgtChangesNotPossible });
export const setCrmChangesNotPossible = crmChangesNotPossible => ({ type: SET_CRM_CHANGES_NOT_POSSIBLE, crmChangesNotPossible });
export const setIsCalculated = isCalculated => ({ type: SET_IS_CALCULATED, isCalculated });
export const setCpp = cpp => ({ type: SET_CPP, cpp });
export const setUniverse = universe => ({ type: SET_UNIVERSE, universe });
export const setShare = share => ({ type: SET_SHARE, share });
export const setTotal = total => ({ type: SET_TOTAL, total });
export const setIsCpp = isCpp => ({ type: SET_IS_CPP, isCpp });
export const setCpt = cpt => ({ type: SET_CPT, cpt });

export const setStartData = () => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  const agemin = getState().input.agemin;
  const agemax = getState().input.agemax;
  const gender = getState().input.gender;
  const genderPost = gender.find(item => item.isChecked) && gender.find(item => item.isChecked).gender;
  const isRestrictions = getState().input.isRestrictions;
  const income = getState().input.income;
  const incomePost = income
    .filter(item => item.isChecked)
    .map(item => item.income)
    .join('');
  const ltv = getState().input.ltv;
  const geo = getState().input.geo;
  // const geoPost = geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).geo;
  const n = geo[0].population / (geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).population);

  dispatch(setCrmChangesNotPossible(1));
  dispatch(setImpChangesNotPossible(0));
  dispatch(setBgtChangesNotPossible(0));
  try {
    const response = await API.post_get_predata2({
      agemax: agemax,
      agemin: agemin,
      gender: genderPost,
      income: incomePost,
      ltv: ltv,
      ots_mult: isRestrictions,
    });
    const impressions = response.data.max_ots_dict[0];
    const newPublishers = Object.keys(impressions).map(item => ({
      Publisher: item,

      Impressions: 0,
      DefaultImpressions: impressions[item] / n,
      CPM: 300,
      Budget: 0,
      // Budget: ((impressions[item] / 10) * 300) / 1000,
      Days: 30,
      FrequencyLimit: 0,
      isChecked: 0,
      TargetingSocDem: 1,
      OtherTargeting: 0,
      Tsa_thousand: 0,
      Tsa_percent: 0,
      TRP: 0,
    }));
    dispatch(setPublishers(newPublishers));
    dispatch(setUniverse(response.data.universe_ta[0] / n));
    dispatch(setShare((response.data.universe_ta[0] / response.data.universe_all[0]) * 100));
    dispatch(setIsInputDataPart1Changed(false));
    dispatch(setMainMedia(null));
    dispatch(setBudget_RadioDistributionChart(null));
    dispatch(setBudgets_RadioIncreaseAggregateCoverageChart({ tvBudget: null, olvBudget: 10000000 }));
    dispatch(setFreqAll(null));
    dispatch(setTotal([{ total_Budget: 0, total_Impressions: 0, total_CPM: 0, total_Tsa_thousand: 0, total_Tsa_percent: 0, total_TRP: 0 }]));
  } catch (error) {
    dispatch(setError(error.message));
  }
  dispatch(setIsOptimization(-1));
  dispatch(setOptimizationData({}));
  dispatch(setStatus('success'));
};

export const setFinalData = () => async (dispatch, getState) => {
  const agemin = getState().input.agemin;
  const agemax = getState().input.agemax;
  const gender = getState().input.gender;
  const genderPost = gender.find(item => item.isChecked) && gender.find(item => item.isChecked).gender;
  const isRestrictions = getState().input.isRestrictions;
  const income = getState().input.income;
  const incomePost = income
    .filter(item => item.isChecked)
    .map(item => item.income)
    .join('');
  const ltv = getState().input.ltv;
  const geo = getState().input.geo;
  const geoPost = geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).geo;
  const n = geo[0].population / (geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).population);

  const publishers = getState().table.publishers;
  const input_data = publishers.map(item => ({
    CPM: item.CPM / n,
    Days: item.isChecked === 1 ? item.Days : 0,
    FrequencyLimit: item.FrequencyLimit,
    Impressions: item.isChecked === 1 ? item.Impressions * n : 0,
    OtherTargeting: item.OtherTargeting,
    Publisher: item.Publisher,
    TargetingSocDem: item.TargetingSocDem,
  }));

  const Impressions_valueArr = input_data.filter(publ => publ.Days !== 0);
  const total_Impressions_value =
    Impressions_valueArr.length > 0
      ? Impressions_valueArr.map(item => item.Impressions).reduce(function (a, b) {
          return +a + +b;
        })
      : 0;

  const CPM_valueArr = input_data.filter(publ => publ.Days !== 0);
  const total_CPM_value = CPM_valueArr.length > 0 ? CPM_valueArr.map(item => item.CPM).reduce((a, b) => +a + +b) / input_data.filter(publ => publ.Days !== 0).length : 0;
  try {
    dispatch(setStatus('loading'));
    const response = await API.post_get_page3_data({
      agemax: agemax,
      agemin: agemin,
      gender: genderPost,
      income: incomePost,
      input_data: input_data,
      ltv: ltv,
      geo: geoPost,
      ots_mult: isRestrictions,
    });

    if (response.status === 200) {
      const totalFromBack = response.data.find(item => item.Publisher === 'Total');

      const totalBudget_value = totalFromBack.Cost;
      const total_Tsa_thousand_value = totalFromBack.ReachTa;
      const total_Tsa_percent_value = totalFromBack.ReachTaPerc;
      const total_TRP_value = totalFromBack.TRP;
      const newTotal = [{ total_Budget: totalBudget_value, total_Impressions: total_Impressions_value, total_CPM: total_CPM_value, total_Tsa_thousand: total_Tsa_thousand_value, total_Tsa_percent: total_Tsa_percent_value, total_TRP: total_TRP_value }];

      const updatedCheckedState = publishers.map(item => {
        const newItem = { ...item };
        newItem['Tsa_percent'] = response.data.find(publ => publ.Publisher === item.Publisher).ReachTaPerc * 100;
        newItem['Tsa_thousand'] = response.data.find(publ => publ.Publisher === item.Publisher).ReachTa;
        newItem['TRP'] = response.data.find(publ => publ.Publisher === item.Publisher).TRP;
        return newItem;
      });

      dispatch(setTotal(newTotal));
      // dispatch(setPublishers(updatedCheckedState));
      dispatch(setMainMedia(null));
      dispatch(setFreqAll(null));
      dispatch(setBudget_RadioDistributionChart(null));
      dispatch(setBudgets_RadioIncreaseAggregateCoverageChart({ tvBudget: null, olvBudget: 10000000 }));

      const input_data_max_ots = publishers.map(item => ({
        Days: item.isChecked === 1 ? item.Days : 30,
        Publisher: item.Publisher,
      }));

      const response_max_Ots = await API.post_get_max_ots({
        agemax: agemax,
        agemin: agemin,
        gender: genderPost,
        income: incomePost,
        ltv: ltv,
        input_data: input_data_max_ots,
        ots_mult: isRestrictions,
      });
      const maxImpressions = response_max_Ots.data;
      const newPublishers = updatedCheckedState.map(item => {
        const newItem = { ...item };
        newItem['DefaultImpressions'] = maxImpressions[item.Publisher] / n;
        return newItem;
      });

      dispatch(setPublishers(newPublishers));
    }
    dispatch(setStatus('success'));
  } catch (error) {
    dispatch(setError(error.message));
  }
};
