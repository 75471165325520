import React from 'react';
import s from './RadioIncreaseAggregateCoverageChart.module.css';
import { Line } from 'react-chartjs-2';
import { round } from '../../../../helpers/chart_helpers';

export const RadioIncreaseAggregateCoverageChart = React.memo(props => {
  const { RadioIncreaseAggregateCoverageChart_data } = props;

  const options = {
    interaction: {
      mode: 'index',
      intersect: false,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: true,
        position: 'bottom',
        align: 'centre',
        labels: {
          boxHeight: 0.5,
        },
      },
      tooltip: {
        enabled: true,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        titleColor: 'black',
        bodyColor: 'black',
        boxHeight: 1,
        callbacks: {
          label: function (TooltipItem) {
            return TooltipItem.dataset.label + ': ' + round(TooltipItem.parsed.y, 1) + ` %`;
          },
          title: function (TooltipItem) {
            return TooltipItem[0].label + ' млн';
          },
        },
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          align: 'end',
          text: 'Совокупный охват Radio&Audio',
          color: 'grey',
          font: {
            size: 13,
          },
          padding: { top: 5, left: 0, right: 0, bottom: 0 },
        },

        ticks: {
          callback: function (val, index) {
            return this.getLabelForValue(val) + '%';
          },
        },
      },
      x: {
        title: {
          display: true,
          align: 'start',
          text: 'Бюджет (млн. руб)',
          color: 'grey',
          font: {
            size: 13,
          },
          padding: 0,
        },
      },
    },
  };
  return (
    <div className={s.wrapper}>
      <Line width={300} height={220} data={RadioIncreaseAggregateCoverageChart_data} options={options} />
    </div>
  );
});
