import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './Income.module.css';
import { Checkbox } from '../../../common/Checkbox/Checkbox';
import { setIncome } from '../../../../../bll/inputParameters_reducer';
import { handleInputDataPart1Changed } from '../../../../../helpers/utils';

export const Income = React.memo(props => {
  const { setIncomeIsSelected } = props;
  const dispatch = useDispatch();
  const income = useSelector(state => state.input.income);

  const handleOnChange = selectedName => {
    const updatedIncomeState = income.map(item => {
      const selectedItem = { ...item };
      selectedItem.isChecked = !selectedItem.isChecked;
      return item.income === selectedName ? selectedItem : item;
    });
    dispatch(setIncome(updatedIncomeState));
    handleInputDataPart1Changed(dispatch);
  };

  const someIncomeSelected = income.find(item => item.isChecked === true);

  useEffect(() => {
    someIncomeSelected && someIncomeSelected.isChecked ? setIncomeIsSelected(true) : setIncomeIsSelected(false);
  }, [income, setIncomeIsSelected, someIncomeSelected]);

  return (
    <div className={s.blockWrapper}>
      <div className={s.blockTitle}>ДОХОД</div>
      <div className={s.blockContent}>
        <div className={s.checkboxWrapper}>
          <Checkbox
            onChangeChecked={() => {
              handleOnChange('A');
            }}
            checked={income[0].isChecked}
            label={'A'}
            id={'A'}
          />
        </div>
        <div className={s.checkboxWrapper}>
          <Checkbox
            onChangeChecked={() => {
              handleOnChange('B');
            }}
            checked={income[1].isChecked}
            label={'B'}
            id={'B'}
          />
        </div>
        <div className={s.checkboxWrapper}>
          <Checkbox
            onChangeChecked={() => {
              handleOnChange('C');
            }}
            checked={income[2].isChecked}
            label={'C'}
            id={'C'}
          />
        </div>
      </div>
    </div>
  );
});
