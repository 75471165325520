import React, { useState } from 'react';
import s from './Select.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';

export const Select = React.memo(props => {
  const { items, value, onChange, borderTitle, location } = props;
  const [selectListVisible, setSelectListVisible] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState(value);
  const onClickSelect = () => {
    setSelectListVisible(!selectListVisible);
  };
  const findSelectHeadStyle = () => {
    if (location === 'optimization') {
      return s.select__head_Optimization;
    } else if (location === 'contr') {
      return s.select__head_Contr;
    } else if (location === 'chart') {
      return s.select__head_Chart_budget;
    } else {
      return s.select__head;
    }
  };

  return (
    <div className={s.select}>
      {borderTitle && <div className={s.blockTitle}>{borderTitle}</div>}
      <input className={s.select__input} type='hidden' name='' />
      <div className={findSelectHeadStyle()} onClick={onClickSelect}>
        {selectListVisible ? null : <FontAwesomeIcon icon={faSortDown} size='2x' style={selectedCategories !== '' ? { display: 'none' } : { color: '#c4c4c4' }} />}
        {selectedCategories}
      </div>
      <ul className={s.select__list} style={selectListVisible ? { display: 'inLine' } : { display: 'none' }}>
        {items.map((item, index) => (
          <li
            key={index}
            className={s.select__item}
            onClick={() => {
              setSelectedCategories(item);
              setSelectListVisible(false);
              onChange(item);
            }}
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
});
