import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './OptimizationOfOlvBudgets.module.css';
import { Select } from '../../components/common/Select/Select';
import { EditableSpan } from '../../components/common/EditableSpan/EditableSpan';
import { Checkbox } from '../../components/common/Checkbox/Checkbox';
import { OptimizationButton } from '../../components/common/OptimizationButton/OptimizationButton';
import { setFreq, setBudget, setIsSupplierBudgetLimits, getOptimization, setRestrictions, setIsOptimization } from '../../../bll/optimization_reducer';
import { setPublishers, setFinalData } from '../../../bll/publishersTable_reducer';
import { getFreqNumber } from '../../../helpers/chart_helpers';
import { makeValueMillions, makeBudgetValue } from '../../../helpers/common_helpers';
import { handleInputDataPart2Changed } from '../../../helpers/utils';

export const OptimizationOfOlvBudgets = React.memo(props => {
  const { modalOptimizationActive, setModalOptimizationActive } = props;
  const isSupplierBudgetLimits = useSelector(state => state.opt.isSupplierBudgetLimits);
  const freq = useSelector(state => state.opt.freq);
  const freqPost = getFreqNumber(freq);
  const budget = useSelector(state => state.opt.budget);
  const publishers = useSelector(state => state.table.publishers);
  const checkedPublishersArr = publishers.filter(item => item.isChecked === 1);
  const restrictions = useSelector(state => state.opt.restrictions);
  const optimizationData = useSelector(state => state.opt.optimizationData);

  const dispatch = useDispatch();
  const onChangeFreq = selectItem => {
    dispatch(setFreq(selectItem));
  };
  const onChangeTotalBudget = newValue => {
    dispatch(setBudget(newValue));
  };
  const onChangeSupplierBudgetLimits = e => {
    dispatch(setIsSupplierBudgetLimits(e.currentTarget.checked ? 1 : 0));
  };

  const onClickOptimization = () => {
    dispatch(getOptimization());
  };
  const applyOptimization = () => {
    const updatedCheckedState = publishers.map(item => {
      const newItem = { ...item };
      newItem['Budget'] = optimizationData[item.Publisher].budget;
      newItem['Impressions'] = (optimizationData[item.Publisher].budget / newItem['CPM']) * 1000;
      return restrictions.find(publ => publ.Publisher === item.Publisher) ? newItem : item;
    });
    dispatch(setPublishers(updatedCheckedState));
    dispatch(setIsOptimization(freqPost));
    dispatch(setFinalData());
    setModalOptimizationActive(false);
    handleInputDataPart2Changed(dispatch);
  };

  const onChangeBudgetLimitsValue = (newValue, mediaName, variable) => {
    const updatedRestrictionsState = restrictions.map(item => {
      const newItem = { ...item };
      newItem[variable] = newValue;
      return item.Publisher === mediaName ? newItem : item;
    });
    dispatch(setRestrictions(updatedRestrictionsState));
  };
  return (
    <div className={modalOptimizationActive ? s.modal + ' ' + s.active : s.modal}>
      <div className={s.optimizationCard}>
        <div className={s.titleBlock}>
          <p style={{ margin: '0' }}>Оптимизация Digital audio бюджетов</p>

          <button
            onClick={() => {
              setModalOptimizationActive(false);
            }}
            className={s.closeButton}
          >
            &times;
          </button>
        </div>

        <Select value={freq} onChange={onChangeFreq} items={['1 +', '2 +', '3 +', '4 +', '5 +', '6 +', '7 +', '8 +', '9 +', '10 +', '11 +', '12 +', '13 +', '14 +', '15 +', '16 +', '17 +', '18 +', '19 +']} borderTitle={'Частота'} location={'optimization'} />
        <EditableSpan value={budget} name={'totalOptBudget'} onChange={onChangeTotalBudget} variable={'PopulationCityX'} location={'totalOptBudget'} borderTitle={'Общий бюджет'} />
        <Checkbox
          onChangeChecked={e => {
            onChangeSupplierBudgetLimits(e);
          }}
          checked={isSupplierBudgetLimits === 1 ? true : false}
          label={'Ограничения на бюджет по поставщикам'}
          id={'Supplier budget limits'}
        />
        {isSupplierBudgetLimits === 1 &&
          checkedPublishersArr.length > 0 &&
          restrictions.map(item => (
            <div className={s.budgetLimitsTableWrapper}>
              {item.Publisher}
              <div style={{ display: 'flex' }}>
                <EditableSpan value={item.min} name={item.Publisher} onChange={onChangeBudgetLimitsValue} variable={'min'} location={'optimizationTable'} borderTitle={'Min'} />
                <EditableSpan value={item.max} name={item.Publisher} onChange={onChangeBudgetLimitsValue} variable={'max'} location={'optimizationTable'} borderTitle={'Max'} />
              </div>
            </div>
          ))}

        {Object.keys(optimizationData).length > 0 && (
          <div className={s.optimizationDataTable}>
            <div style={{ display: 'flex' }}>
              <div className={s.optimizationDataTableCell}>Площадка</div>
              <div className={s.optimizationDataTableCell}>Impressions</div>
              <div className={s.optimizationDataTableCell}>Бюджет</div>
            </div>

            {Object.keys(optimizationData).length > 0 &&
              restrictions.map(item => (
                <div className={s.optimizationDataRow}>
                  <div className={s.optimizationDataTableCell}>{item.Publisher}</div>
                  <div className={s.optimizationDataTableCell}>{makeValueMillions((optimizationData[item.Publisher].budget / publishers.find(publ => publ.Publisher === item.Publisher).CPM) * 1000)}</div>
                  <div className={s.optimizationDataTableCell}>{makeBudgetValue(optimizationData[item.Publisher].budget)}</div>
                </div>
              ))}
          </div>
        )}
        <div className={s.optBtnsWrapper}>
          <OptimizationButton onClick={onClickOptimization} title={'ОПТИМИЗИРОВАТЬ'} />
          {Object.keys(optimizationData).length > 0 && <OptimizationButton onClick={applyOptimization} title={'ПРИМЕНИТЬ'} />}
        </div>
      </div>
    </div>
  );
});
