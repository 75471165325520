import React from 'react';
import s from './AudioDoughnutChart.module.css';
import { Doughnut } from 'react-chartjs-2';
import { setMillion } from '../../../../helpers/common_helpers';

export const AudioDoughnutChart = React.memo(props => {
  const { olvDonutData } = props
  const maxValueArr = olvDonutData.datasets && olvDonutData.datasets[0].data;
  const maxValue =
    maxValueArr &&
    maxValueArr.reduce(function (accumulator, currentValue) {
      return accumulator + +currentValue;
    }, 0);
  const visibleMaxValue = 5;
  const maxValuePr = (maxValue / 100) * visibleMaxValue; //вычисление процентов
  const donutDataLabels = olvDonutData && olvDonutData.labels;
  const chooseLegendSize = () => {
    if (donutDataLabels && donutDataLabels.length > 10) {
      return {
        boxHeight: 10,
        padding: 1,
      };
    } else if (donutDataLabels && donutDataLabels.length > 7) {
      return {
        boxHeight: 10,
        padding: 5,
      };
    } else {
      return {};
    }
  };
  const options = {
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: 'true',
        formatter: function (value) {
          if (+value > maxValuePr) {
            const changedValue = setMillion(value);
            return changedValue;
          } else {
            return '';
          }
        },
        labels: {
          title: {
            font: {
              size: '14px',
              weight: 'bold',
            },
          },
        },
        anchor: 'end',
        align: 'start',
        color: 'white',
      },
      legend: {
        display: true,
        position: 'right',
        align: 'centre',
        labels: chooseLegendSize(),
      },
      tooltip: {
        enabled: true,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        titleColor: 'black',
        bodyColor: 'black',
        callbacks: {
          label: function (TooltipItem) {
            const changedTooltipItem = setMillion(TooltipItem.parsed);
            return TooltipItem.label + ': ' + changedTooltipItem + ' млн';
          },
        },
      },
    },
  };

  return (
    <div style={{ margin: '10px' }} className={s.wrapper}>
      <Doughnut width={300} height={180} data={olvDonutData} options={options} />
    </div>
  );
});
