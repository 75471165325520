import React from 'react';
import { useSelector } from 'react-redux';
import pptxgen from 'pptxgenjs';
import colorStar from '../../../assets/images/Picture1.png';
import { colorsArr } from '../../../helpers/chart_helpers';
import { COLORS } from '../../../assets/styles/colors/colors';
import DigAudioBig from '../../../assets/images/DigAudioBig.png';
import DigAudioOptSmall from '../../../assets/images/DigAudioOptSmall.png';
import RadioSmall from '../../../assets/images/RadioSmall.png';
import grayBackground from '../../../assets/images/grayBackground.jpg';
import logoStar from '../../../assets/images/logoStar.png';
import redLinePpt from '../../../assets/images/redLinePpt.png';
import { makeGeoTitlePPT, makeIncomeTitle, makeGenderTitle, findValueView } from '../../../helpers/common_helpers';

export const ExportPPTOlv = React.memo(props => {
  const freqAll = useSelector(state => state.change.freqAll);
  const universe = useSelector(state => state.table.universe);
  const geo = useSelector(state => state.input.geo);
  const gender = useSelector(state => state.input.gender);
  const income = useSelector(state => state.input.income);
  const agemin = useSelector(state => state.input.agemin);
  const agemax = useSelector(state => state.input.agemax);
  const isCBUUpload = useSelector(state => state.input.isCBUUpload);
  const publishers = useSelector(state => state.table.publishers);
  const checkedPublDaysArr = publishers.filter(item => item.isChecked === 1).map(item => item.Days);
  const textMedia = isCBUUpload === 0 ? 'Digital Audio' : 'Digital Audio, Radio';
  const textPeriod = Math.max.apply(null, checkedPublDaysArr);

  const chartsData = useSelector(state => state.charts.chartsData);
  const dataFromXL = useSelector(state => state.charts.dataFromXL);
  const isChartsUpload = useSelector(state => state.charts.isChartsUpload);
  const RFOpen = !isChartsUpload ? chartsData.RF['Total_media_rf_n+'] : Object.values(dataFromXL.filter(item => item['OLVReachN+'])[0]).slice(0, -1);
  const RFClose = !isChartsUpload ? chartsData.RF['Total_media_rf_n'] : Object.values(dataFromXL.filter(item => item['OLVReachN'])[0]).slice(0, -1);

  const AudioContributionOfEachMedia_data = useSelector(state => state.charts.AudioContributionOfEachMedia_data);
  const olvDonutData = useSelector(state => state.charts.olvDonutData);
  const olvDistribution_data = useSelector(state => state.charts.olvDistribution_data);
  const AudioCbuCprpChartData = useSelector(state => state.charts.AudioCbuCprpChartData);

  let pptx = new pptxgen();
  pptx.layout = 'LAYOUT_WIDE';
  pptx.defineSlideMaster({
    title: 'MASTER_SLIDE',
    objects: [{ text: { text: 'AudioTron', options: { x: '11%', y: '7%', w: 5.5, h: 0.75 } } }, { image: { y: '8%', w: '100%', h: '8%', data: colorStar } }, { text: { text: 'Источник: https://audiotron.mediainstinctgroup.ru', options: { x: 1, y: '90%', w: 5.5, h: 0.75, fontSize: 11 } } }],
    slideNumber: { x: '94%', y: '9%' },
  });
  let slide = pptx.addSlide({ masterName: 'MASTER' });
  slide.background = { path: grayBackground };
  slide.addImage({ path: logoStar, x: 1, y: 1, w: '16%', h: '11%' });
  slide.addText('Результат планирования', { x: '10%', y: '30%', w: '100%', h: 3.2, color: '#e10500', fontSize: 70, bold: true });
  slide.addText('AudioTron', { x: '70%', y: '60%', w: 6, h: 3.2, color: '#080808', fontSize: 45, bold: true });

  let slide1 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
  slide1.background = { color: '#dfe4e8' };
  slide1.addText('Основные вводные планирования', { x: 1, y: 0, w: 8, h: 3.2, color: '#080808', fontSize: 35, bold: true });

  let TATextBlock = [
    { text: 'ЦЕЛЕВАЯ АУДИТОРИЯ:', options: { fontSize: 25, color: '#ffffff', breakLine: true, bold: true } },
    { text: `${makeGenderTitle(gender)} ${agemin}-${agemax} ${makeIncomeTitle(income)}`, options: { fontSize: 35, color: '#ffffff', bold: true } },
  ];
  let UnTextBlock = [
    { text: 'UNIVERSE:', options: { fontSize: 30, color: '#080808', breakLine: true, bold: true } },
    { text: `${findValueView(universe, 'Universe')}`, options: { fontSize: 35, color: '#284d64', breakLine: true, bold: true } },
    { text: 'тыс. чел', options: { fontSize: 25, color: '#080808' } },
  ];
  let PerTextBlock = [
    { text: 'ПЕРИОД:', options: { fontSize: 30, color: '#080808', breakLine: true, bold: true } },
    { text: `${textPeriod}`, options: { fontSize: 35, color: '#284d64', breakLine: true, bold: true } },
    { text: 'дней', options: { fontSize: 25, color: '#080808' } },
  ];
  let FreqTextBlock = [
    { text: 'ЧАСТОТА, НА КОТОРУЮ ', options: { fontSize: 25, color: '#080808', breakLine: true, bold: true } },
    { text: 'СМОТРИМ РЕЗУЛЬТАТ', options: { fontSize: 25, color: '#080808', breakLine: true, bold: true } },
    { text: '(на флайт)', options: { fontSize: 25, color: '#080808', breakLine: true, bold: true } },
    { text: `${freqAll}`, options: { fontSize: 50, color: '#284d64', bold: true } },
  ];
  let GeoTextBlock = [
    { text: 'ГЕО: ', options: { fontSize: 30, color: '#080808', breakLine: true, bold: true } },
    { text: `${makeGeoTitlePPT(geo)}`, options: { fontSize: 35, color: '#284d64', bold: true } },
  ];
  let MediaTextBlock = [
    { text: 'МЕДИА:', options: { fontSize: 30, color: '#080808', breakLine: true, bold: true } },
    { text: `${textMedia}`, options: { fontSize: 29, color: '#284d64', bold: true } },
  ];

  slide1.addText(TATextBlock, { x: 1, y: 2, w: 5, h: 2, fill: { color: '#0d2e44' }, margin: 40 });
  slide1.addText(UnTextBlock, { x: 6.2, y: 2, w: 3, h: 2.5, fill: { color: '#ffffff' }, margin: 40 });
  slide1.addText(PerTextBlock, { x: 9.4, y: 2, w: 3, h: 2.5, fill: { color: '#ffffff' }, margin: 40 });
  slide1.addText(FreqTextBlock, { x: 1, y: 4.2, w: 5, h: 3, fill: { color: '#ffffff' }, margin: 40 });
  slide1.addText(GeoTextBlock, { x: 6.2, y: 4.7, w: 3, h: 2.5, fill: { color: '#ffffff' }, margin: 40 });
  slide1.addText(MediaTextBlock, { x: 9.4, y: 4.7, w: 3, h: 2.5, fill: { color: '#ffffff' }, margin: 25 });
  slide1.addImage({ path: redLinePpt, x: 1, y: 2, w: 5, h: '0.8%' });

  let slide2 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
  slide2.addText(`Вклад каждой площадки в совокупный охват на частоте ${freqAll}`, { x: 2.5, y: 0, w: '100%', h: 3.2, color: '#080808', fontSize: 25, bold: true });
  slide2.addImage({ path: RadioSmall, x: -0.05, y: 5.23, w: '9%', h: '19%' });
  slide2.addImage({ path: DigAudioOptSmall, x: -0.05, y: 3.93, w: '9%', h: '19%' });
  slide2.addImage({ path: DigAudioBig, x: -0.17, y: 2.3, w: '15%', h: '26%' });
  let optsChartBar1 = {
    x: 1.5,
    y: 2.5,
    w: '80%',
    h: '50%',
    catGridLine: { style: 'none' },
    valGridLine: { style: 'none' },
    legendFontSize: 16,
    barDir: 'bar',
    catAxisOrientation: 'maxMin',
    catAxisLabelColor: '808080',
    barGrouping: 'stacked',
    dataLabelColor: 'FFFFFF',
    // dataLabelPosition: 'outEnd',
    showValue: true,
    chartColors: [COLORS.red],
    valAxisLabelColor: '808080',
    dataLabelFormatCode: '0.00',

    showValAxisTitle: true,
    valAxisTitle: 'Охват ЦА, %',
    valAxisTitleFontSize: 14,
    valAxisTitleAlign: 'top',

    showCatAxisTitle: true,
    catAxisTitle: 'Площадки',
    catAxisTitleFontSize: 14,
  };

  let single = [
    {
      name: 'Conrtibution',
      labels: AudioContributionOfEachMedia_data.labels,
      values: AudioContributionOfEachMedia_data.datasets[0].data,
    },
  ];
  slide2.addChart(pptx.charts.BAR, single, optsChartBar1);

  let slide3 = pptx.addSlide('MASTER_SLIDE');
  const dataChartPieStat = [
    {
      name: 'Pie',
      labels: olvDonutData.labels,
      values: olvDonutData.datasets[0].data,
    },
  ];

  slide3.addText('Распределение бюджета по площадкам', { x: 2.5, y: 0, w: 8, h: 3.2, color: '#080808', fontSize: 25, bold: true });
  slide3.addImage({ path: RadioSmall, x: -0.05, y: 5.23, w: '9%', h: '19%' });
  slide3.addImage({ path: DigAudioOptSmall, x: -0.05, y: 3.93, w: '9%', h: '19%' });
  slide3.addImage({ path: DigAudioBig, x: -0.17, y: 2.3, w: '15%', h: '26%' });
  let optsPie = {
    x: 2.5,
    y: 2,
    w: '50%',
    h: '50%',
    legendPos: 't',
    legendFontFace: 'Roboto',
    legendFontSize: 16,
    showLegend: true,
    showLeaderLines: true,
    legendColor: '808080',
    showPercent: false,

    showValue: true,
    chartColors: colorsArr,
    dataBorder: { pt: '1', color: 'F1F1F1' },
    dataLabelColor: 'FFFFFF',
    dataLabelFormatCode: '#,##0 ₽',
    dataLabelFontSize: 12,
    dataLabelPosition: 'ctr', //outEnd', // 'bestFit' | 'outEnd' | 'inEnd' | 'ctr'
  };
  slide3.addChart(pptx.charts.DOUGHNUT, dataChartPieStat, optsPie);

  let slide4 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
  slide4.addText('Распределение Reach / Frequency (Digital audio)', { x: 2.2, y: 0, w: '100%', h: 3.2, color: '#080808', fontSize: 25, bold: true });
  slide4.addImage({ path: RadioSmall, x: -0.05, y: 5.23, w: '9%', h: '19%' });
  slide4.addImage({ path: DigAudioOptSmall, x: -0.05, y: 3.93, w: '9%', h: '19%' });
  slide4.addImage({ path: DigAudioBig, x: -0.17, y: 2.3, w: '15%', h: '26%' });
  let arrOlvDistribution_data = [
    {
      name: 'Открытые',
      labels: olvDistribution_data.labels,
      values: RFOpen,
    },
    {
      name: 'Закрытые',
      labels: olvDistribution_data.labels,
      values: RFClose,
    },
  ];
  let olvChart4 = {
    x: 1.5,
    y: 2.5,
    w: '80%',
    h: '50%',
    barDir: 'col',
    catGridLine: { style: 'none' },
    valGridLine: { style: 'none' },
    catAxisLabelColor: '808080',
    catAxisLabelFontFace: 'Helvetica Neue',
    catAxisLabelFontSize: 14,
    catAxisOrientation: 'minMax',
    valAxisLabelColor: '808080',
    // valAxisLabelFormatCode: '0%',
    chartColors: [COLORS.red, COLORS.veryDarkGray],
    legendPos: 'b',
    legendFontSize: 16,
    legendColor: '808080',
    showLegend: true,

    showValAxisTitle: true,
    valAxisTitle: 'Охват (%)',
    valAxisTitleFontSize: 14,
    valAxisTitleAlign: 'left',

    showCatAxisTitle: true,
    catAxisTitle: 'Частота',
    catAxisTitleFontSize: 14,
  };
  slide4.addChart(pptx.charts.BAR, arrOlvDistribution_data, olvChart4);

  let slide5 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
  slide5.addText(`Digital audio: CBU & CPRP на частоте ${freqAll}`, { x: 2.2, y: 0, w: '100%', h: 3.2, color: '#080808', fontSize: 25, bold: true });
  slide5.addImage({ path: RadioSmall, x: -0.05, y: 5.23, w: '9%', h: '19%' });
  slide5.addImage({ path: DigAudioOptSmall, x: -0.05, y: 3.93, w: '9%', h: '19%' });
  slide5.addImage({ path: DigAudioBig, x: -0.17, y: 2.3, w: '15%', h: '26%' });

  let arrAudioCbuCprpChartData = [
    {
      name: 'Reach',
      labels: AudioCbuCprpChartData.labels,
      values: AudioCbuCprpChartData.datasets[0].data.map(item => (item === 'null' ? null : item)),
    },
    {
      name: 'Incr.Reach',
      labels: AudioCbuCprpChartData.labels,
      values: AudioCbuCprpChartData.datasets[1].data.map(item => (item === 'null' ? null : item)),
    },
    {
      name: 'CPRP',
      labels: AudioCbuCprpChartData.labels,
      values: AudioCbuCprpChartData.datasets[2].data.map(item => (item === 'null' ? null : item)),
    },
  ];

  let olvChart5 = {
    x: 1.5,
    y: 2.5,
    w: '80%',
    h: '50%',

    chartColors: [COLORS.red, COLORS.veryDarkGray, COLORS.gray],
    catAxisLabelColor: '808080',
    valAxisLabelColor: '808080',
    // valAxisLabelFormatCode: '0%',
    lineDataSymbol: 'none',
    catGridLine: { style: 'none' },
    valGridLine: { style: 'none' },
    legendPos: 'b',
    legendFontSize: 16,
    legendColor: '808080',
    showLegend: true,
    valAxisMinVal: 0,
    valAxisMaxVal: 100,
    catAxisMinVal: 0,

    showValAxisTitle: true,
    valAxisTitle: 'Инкрементал Охват (%)',
    valAxisTitleFontSize: 14,
    valAxisTitleAlign: 'left',

    showCatAxisTitle: true,
    catAxisTitle: 'Бюджет (млн. ₽)',
    catAxisTitleFontSize: 14,
  };

  slide5.addChart(pptx.charts.LINE, arrAudioCbuCprpChartData, olvChart5);

  pptx.writeFile({ fileName: 'auditron.pptx' });
  return <div></div>;
});
