import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './Step3Page.module.css';
import { Warning } from '../../components/common/Warning/Warning';
import { ToggleSwitch } from '../../components/common/ToggleSwitch/TiggleSwitch';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { RedButton } from '../../components/common/RedButton/RedButton';
import { TableButton } from '../../components/common/TableButton/TableButton';
import { PublishersChoiceTable } from '../../components/PublishersChoiceTable/PublishersChoiceTable';
import { useHistory } from 'react-router-dom';
import { Checkbox } from '../../components/common/Checkbox/Checkbox';
import optimize from '../../../assets/images/optimize.png';
import { OptimizationOfOlvBudgets } from '../../components/OptimizationOfOlvBudgets/OptimizationOfOlvBudgets';
import { findValueView } from '../../../helpers/common_helpers';
import { setCpp, setFinalData, setIsCpp, setCpt } from '../../../bll/publishersTable_reducer';
import { EditableSpan } from '../../components/common/EditableSpan/EditableSpan';
import { post_get_all_data } from '../../../bll/charts_reducer';
import { setIsInputDataChanged, setCbuData, setIsRestrictions } from '../../../bll/inputParameters_reducer';
import { setBudget, setIsOptimization, setOptimizationData, setRestrictions } from '../../../bll/optimization_reducer';
import { handleInputDataPart2Changed } from '../../../helpers/utils';

export const Step3Page = React.memo(props => {
  const [modalOptimizationActive, setModalOptimizationActive] = useState(false);
  const [totalBudgetZero, setTotalBudgetZero] = useState(true);
  const [cppZeroWarning, setСppZeroWarning] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const presenceOfTvFlight = useSelector(state => state.input.presenceOfTvFlight);
  const cpp = useSelector(state => state.table.cpp);
  const universe = useSelector(state => state.table.universe);
  const share = useSelector(state => state.table.share);
  const publishers = useSelector(state => state.table.publishers);
  const somePublSelected = publishers.filter(item => item.isChecked === 1);
  const isInputDataChanged = useSelector(state => state.input.isInputDataChanged);
  const isOptimization = useSelector(state => state.opt.isOptimization);
  const cbuData = useSelector(state => state.input.cbuData);
  const isRestrictions = useSelector(state => state.input.isRestrictions);
  const isCpp = useSelector(state => state.table.isCpp);
  const cpt = useSelector(state => state.table.cpt);

  const onClick_CPP_ToggleSwitch = e => {
    e.currentTarget.checked === true ? dispatch(setIsCpp(0)) : dispatch(setIsCpp(1));
    const newCPT = (cpp * 100) / universe;
    dispatch(setCpt(newCPT));
  };

  const onClickNextButton = () => {
    if (presenceOfTvFlight === 1 && cpp === 0) {
      setСppZeroWarning(true);
    } else {
      history.push('/step4');
      isInputDataChanged === true && dispatch(post_get_all_data());
      dispatch(setIsInputDataChanged(false));
    }
  };
  const onClickBackButton = () => {
    history.push('/step2');
  };
  const onClickOptimizationBtn = () => {
    setModalOptimizationActive(true);
  };
  const onChangeCpp = newValue => {
    dispatch(setCpp(+newValue));
    handleInputDataPart2Changed(dispatch);
    const newCPT = (+newValue * 100) / universe;
    dispatch(setCpt(newCPT));

    const newCbuData = { ...cbuData };
    newCbuData.CPP = +newValue;
    dispatch(setCbuData(newCbuData));
  };

  const onChangeCpt = newValue => {
    dispatch(setCpt(+newValue));
    handleInputDataPart2Changed(dispatch);
    const newCPP = (+newValue * universe) / 100;
    dispatch(setCpp(newCPP));
    const newCbuData = { ...cbuData };
    newCbuData.CPP = newCPP;
    dispatch(setCbuData(newCbuData));
  };
  const onChangeIsRestrictions = e => {
    e.target.checked ? dispatch(setIsRestrictions(1)) : dispatch(setIsRestrictions(0));
    handleInputDataPart2Changed(dispatch);
    dispatch(setFinalData());
    dispatch(setIsOptimization(-1));
    dispatch(setOptimizationData({}));
    const checkedPublishersArr = publishers.filter(item => item.isChecked === 1);
    const newRestrictions = checkedPublishersArr.map(item => ({ Publisher: item.Publisher, min: null, max: null }));
    dispatch(setRestrictions(newRestrictions));
  };
  useEffect(() => {
    isOptimization === -1 && dispatch(setBudget(10000000));
  }, [dispatch, isOptimization]);

  const isInitialized = useSelector(state => state.app.isInitialized);
  useEffect(() => {
    if (!isInitialized) {
      history.push('/signin');
    }
  }, [isInitialized, history]);

  return (
    <div className={s.step3Wrapper}>
      <PageHeader pageHeaderTitle={'Вводные параметры для планирования'} pageHeaderNumber={'3.'} withRedTitle />

      <div className={s.nextButtonWrapper}>
        <RedButton onClick={onClickBackButton} title={'Вернуться назад'} />
      </div>

      <div className={s.universeAndCPPWrapper}>
        <div className={presenceOfTvFlight === 1 ? s.CPPWrapper : s.CPPWrapperNone}>
          <ToggleSwitch
            onChangeChecked={e => {
              onClick_CPP_ToggleSwitch(e);
            }}
            checked={isCpp === 1 ? false : true}
            labelStart={'CPT TA'}
            labelEnd={'CPP TA'}
            id={'toggle_switch_CPP'}
          />
          {isCpp === 1 ? <EditableSpan value={cpp} name={'cpp'} onChange={onChangeCpp} variable={'Cpp'} location={'Cpp'} /> : <EditableSpan value={cpt} name={'cpt'} onChange={onChangeCpt} variable={'Cpp'} location={'Cpp'} />}
        </div>

        <div className={s.universeWrapper}>
          <div>Universe: {findValueView(universe, 'Universe')}</div>
          <div>Share: {findValueView(share, 'Share')} </div>
        </div>
      </div>

      <PublishersChoiceTable setTotalBudgetZero={setTotalBudgetZero} />

      <div className={s.buttonsWrapper}>
        <TableButton onClick={onClickOptimizationBtn} redTitle={'Оптимизировать'} blackTitle={'бюджет / сплит'} isDisabled={somePublSelected.length > 1 ? false : true}>
          <img src={optimize} alt='optimize' style={{ width: '30px', height: '30px' }} />
        </TableButton>
        <div style={{ marginRight: '20px', marginLeft: '20px' }}>
          <Checkbox
            onChangeChecked={e => {
              onChangeIsRestrictions(e);
            }}
            checked={isRestrictions === 1 ? true : false}
            label={'Учитывать ограничения емкости инвентаря каждой\n площадки для выбранного количества дней?'}
            id={'IsRestrictions'}
          />
        </div>
        <RedButton onClick={onClickNextButton} title={'Следующий шаг - построить\n графики'} isDisabled={somePublSelected && somePublSelected.length > 0 && !totalBudgetZero ? false : true} />
      </div>
      {modalOptimizationActive && <OptimizationOfOlvBudgets modalOptimizationActive={modalOptimizationActive} setModalOptimizationActive={setModalOptimizationActive} />}
      <Warning text={'Стоимость размещения на радио должна быть больше нуля.'} warningVisible={cppZeroWarning} setWarningVisible={setСppZeroWarning} />
    </div>
  );
});
