import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { inputParameters_reducer } from './inputParameters_reducer';
import { optimization_reducer } from './optimization_reducer';
import { publishersTable_reducer } from './publishersTable_reducer';
import { app_reducer } from './app_reducer';
import { charts_reducer } from './charts_reducer';
import { change_charts_reducer } from './change_charts_reducer';

const rootReducer = combineReducers({
  input: inputParameters_reducer,
  opt: optimization_reducer,
  table: publishersTable_reducer,
  app: app_reducer,
  charts: charts_reducer,
  change: change_charts_reducer,
});
const input = sessionStorage.getItem('input')
  ? JSON.parse(sessionStorage.getItem('input'))
  : {
      gender: [
        { id: 1, isChecked: false, gender: 'WOMEN' },
        { id: 2, isChecked: false, gender: 'MEN' },
        { id: 3, isChecked: true, gender: 'ALL' },
      ],
      income: [
        { id: 1, isChecked: false, income: 'A' },
        { id: 2, isChecked: true, income: 'B' },
        { id: 3, isChecked: true, income: 'C' },
      ],
      geo: [
        { id: 1, name: 'NAT', isChecked: true, geo: 'NAT', population: 146171015 },
        { id: 2, name: 'Moscow', isChecked: false, geo: 'Moscow', population: 12655050 },
        { id: 3, name: 'Petersburg', isChecked: false, geo: 'Petersburg', population: 5384342 },
        { id: 4, name: 'cityХ', isChecked: false, geo: 'cityХ', population: 0 },
      ],
      agemin: 18,
      agemax: 45,
      ltv: 0,
      presenceOfTvFlight: 0,
      isInputDataChanged: false,
      isInputDataPart1Changed: true,
      isCBUUpload: 0,
      cbu: null,
      cbuData: null,
      tv_rf: [],
      postData: [{}],
      isRestrictions: 1,
    };
const opt = sessionStorage.getItem('opt')
  ? JSON.parse(sessionStorage.getItem('opt'))
  : {
      budget: 10000000,
      freq: '1+',
      restrictions: [{ Publisher: '', min: null, max: null }],
      workers: -1,
      isSupplierBudgetLimits: 0,
      optimizationData: {},
      isOptimization: -1,
    };

const table = sessionStorage.getItem('table')
  ? JSON.parse(sessionStorage.getItem('table'))
  : {
      publishers: [],
      impChangesNotPossible: 0,
      bgtChangesNotPossible: 0,
      crmChangesNotPossible: 1,
      isCalculated: false,
      cpp: 28571,
      universe: 0,
      share: 0,
      total: [{ total_Budget: 0, total_Impressions: 0, total_CPM: 0, total_Days: 0, total_Tsa_thousand: 0, total_Tsa_percent: 0, total_TRP: 0 }],
      isCpp: 0,
      cpt: 75,
    };

const charts = sessionStorage.getItem('charts')
  ? JSON.parse(sessionStorage.getItem('charts'))
  : {
      chartsData: {},
      chartsData_TV_OLV_RFdistr2: {},
      chartsData_TV_OLV_20_view: {},
      dataFromXL: [],
      isChartsUpload: false,
      AudioContributionOfEachMedia_data: {},
      olvDonutData: {},
      olvDistribution_data: {},
      AudioCbuCprpChartData: {},
      optInvestmentChart_data: {},
      optComparisonChart_data: {},
      RadioDistributionChart_data: {},
      RadioIncreaseAggregateCoverageChart_data: {},
      RadioOptimalDistributionChart_data: {},
      RadioOptimalSplitChart_data: {},
    };

const change = sessionStorage.getItem('change')
  ? JSON.parse(sessionStorage.getItem('change'))
  : {
      mainMedia: null,
      mainMediaArr: [],
      N_OLV: 1,
      N_TV: 1,
      freqAll: null,
      budget_AudioCbuCprpChart: 10000000,
      budget_RadioDistributionChart: null,
      budgets_RadioIncreaseAggregateCoverageChart: { tvBudget: null, olvBudget: 10000000 },
    };

const persistedState = { input, opt, table, charts, change };

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(rootReducer, persistedState, composeEnhancers(applyMiddleware(thunkMiddleware)));

store.subscribe(() => {
  sessionStorage.setItem('input', JSON.stringify(store.getState().input));
  sessionStorage.setItem('opt', JSON.stringify(store.getState().opt));
  sessionStorage.setItem('table', JSON.stringify(store.getState().table));
  sessionStorage.setItem('charts', JSON.stringify(store.getState().charts));
  sessionStorage.setItem('change', JSON.stringify(store.getState().change));
});
